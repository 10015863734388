<template>
  <v-container align-center fill-height>
    <v-layout align-center fill-height>
      <v-flex>
        <h1 class="display-1">Sorry. Page Not Found :(</h1>
        <span
          class="subheading"
        >Use the navigation links above to navigate between the different modules.</span>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

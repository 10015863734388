<template>
  <v-snackbar
    :timeout="snackbar.timeout"
     :multi-line="snackbar.multiline"
    :color="snackbar.type"
    top
    v-model="snackbar.visible">
    {{ snackbar.text }}
    <v-btn flat dark @click.native="closeSnackbar">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  computed: {
    snackbar () {
      return this.$store.state.snackbar;
    }
  },
  methods: {
    ...mapActions(["closeSnackbar"])
  }
};
</script>

<template>
  <v-container fluid>
    <h1 class="display-2 mb-5">Laboratoires</h1>

    <v-card class="mb-3">
      <!-- Search bar -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          color="secondary"
          dark
          small
          fab
          class="ml-4"
          id="newLaboratoryBtn"
          @click="createLaboratoryDialog = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <!-- laboratories table -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="laboratories"
        :search="search"
        :pagination.sync="pagination"
        select-all
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr>
            <td class="text-xs-center">
              <v-btn
                class="btn"
                color="info"
                fab
                flat
                small
                dark
                relative
                :right="true"
                @click="showEditForm(props.item._id)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                class="btn"
                color="error"
                fab
                flat
                small
                dark
                relative
                :right="true"
                @click="showDeleteForm(props.item._id)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-center">{{ props.item.acronym }}</td>
            <td class="text-xs-left">
              {{ props.item.director.lastName }}
              {{ props.item.director.firstName }}
            </td>
            <td class="text-xs-left">{{ props.item.director.email }}</td>
          </tr>
        </template>
        <v-alert
          slot="no-results"
          :value="true"
          color="error"
          icon="warning"
        >Nous ne trouvons rien avec "{{ search }}" comme clé :(.</v-alert>
        <v-alert
          slot="no-data"
          :value="true"
          color="light-blue"
          icon="info"
        >Il semblerait qu'il n'y ait pas encore de laboratoire créé.</v-alert>
      </v-data-table>
    </v-card>

    <!-- new form -->
    <v-dialog :value="createLaboratoryDialog" persistent max-width="1000px">
      <LaboratoryForm label="Add" @close="closeLaboratoryDialogs" @success="refreshLaboratoryView" />
    </v-dialog>

    <!-- edit form -->
    <v-dialog :value="updateLaboratoryDialog" persistent max-width="1000px">
      <LaboratoryForm
        :id="laboratoryId"
        label="Update"
        @close="closeLaboratoryDialogs"
        @success="refreshLaboratoryView"
      />
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog :value="deleteLaboratoryDialog" max-width="510px" :scrollable="false" persistent>
      <template>
        <v-card>
          <v-card-title class="headline primary--text">Suppression de laboratoire</v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12>
                <p>Êtes-vous sur de vouloir supprimer ce laboratoire ?</p>
                <p>Attention une fois le laboratoire supprimé, il sera impossible de le récupérer !!</p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider class="mt-1" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="secondary" dark @click="closeLaboratoryDialogs">Non</v-btn>
            <v-btn color="primary" dark @click="deleteLaboratory(laboratoryId)">Oui</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LaboratoryForm from "@/components/laboratory/LaboratoryForm.vue";

export default {
  name: "Laboratory",
  components: {
    LaboratoryForm
  },
  data: () => ({
    search: "",
    createLaboratoryDialog: false,
    updateLaboratoryDialog: false,
    deleteLaboratoryDialog: false,
    laboratoryId: null,
    pagination: {
      sortBy: "acronym",
      rowsPerPage: -1
    },
    selected: [],
    headers: [
      { text: "Actions", value: "_id" },
      { text: "Acronym", value: "acronym" },
      { text: "Directeur", value: "director.lastName" },
      { text: "Mail", value: "director.email" }
    ],
    laboratories: []
  }),
  mounted () {
    this.$axios.get("auth/loggedin")
      .then(() => this.fetchLaboratories())
      .catch(() => {
        this.showSnackbar({
          text: "Oups ! il semble que votre session ait expirée :(",
          type: "error",
          multiline: true
        });
        this.$store.dispatch("logout")
          .then(() => this.$router.push({ name: "home" }));
      });
  },
  computed: {
    ...mapGetters([
      "snackbarVisible",
      "user" // username of the logged user
    ])
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Fetch all the created laboratories
     */
    fetchLaboratories () {
      return this.$axios.get("/laboratory")
        .then(response => {
          this.laboratories = response.data;
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    /**
     * Deletes a laboratory
     * @param {string} id - laboratory's id
     */
    deleteLaboratory (id) {
      this.$axios.delete("/laboratory/" + id)
        .then(() => this.refreshLaboratoryView())
        .catch(err => console.log(err));
    },
    showDeleteForm (id) {
      this.laboratoryId = id;
      this.deleteLaboratoryDialog = true;
    },
    /**
     * Shows the form for editing a laboratory
     * @param {string} id - laboratory's id
     */
    showEditForm (id) {
      this.laboratoryId = id;
      this.updateLaboratoryDialog = true;
    },
    /**
     * Shows the form for creating a laboratory
     */
    showCreateForm () {
      this.laboratoryId = undefined;
      this.updateLaboratoryDialog = true;
    },
    closeLaboratoryDialogs () {
      this.createLaboratoryDialog = false;
      this.updateLaboratoryDialog = false;
      this.deleteLaboratoryDialog = false;
      this.laboratoryId = null;
    },
    refreshLaboratoryView () {
      this.closeLaboratoryDialogs();
      this.fetchLaboratories();
    }
  }
};
</script>

<style lang="scss" >
.btn {
  margin: 0%;
}

.rankTextField {
  min-width: 120px;
  input {
    text-align: center;
  }
}

.buttons-table {
  position: relative;
  bottom: 55px;
}

.buttons-actions {
  min-width: 220px;
}
.buttons-status {
  min-width: 200px;
}
</style>

import axios from "@/axios";

const state = {
  fakeUser: {
    fakeUsername: localStorage.getItem("fakeUsername") || null,
    fakeEmail: localStorage.getItem("fakeEmail") || null
  }
};

/**
 * Function that creates an fakeUser object
 *
 * @param {Object} fakeUser
 *
 * @return {Object} - an fakeUser object
 */
function createFakeUser (fakeUser = { username: null, email: null }) {
  return { fakeUsername: fakeUser.username, fakeEmail: fakeUser.email };
}

const mutations = {
  SET_FAKEUSER (state, fakeUser) {
    if (fakeUser && fakeUser.username) {
      state.fakeUser = createFakeUser(fakeUser);
    } else {
      state.fakeUser = createFakeUser();
    }
  },
  SET_FAKEUSERNAME (state, fakeUser) {
    state.fakeUser.username = fakeUser.username;
  }
};

const actions = {
  fakeLogin ({ commit }, payload) {
    return axios.get(`/auth/fakeSession/${payload.id}`)
      .then(result => {
        if (result.data.email) {
          localStorage.setItem("fakeUsername", result.data.username);
          localStorage.setItem("fakeEmail", result.data.email);
          commit("SET_FAKEUSER", result.data);
        } else {
          localStorage.setItem("fakeUsername", result.data.username);
          commit("SET_FAKEUSERNAME", result.data);
        }
      })
      .catch(error => { return { "error": error }; });
  },
  fakeLogout ({ commit }) {
    return axios.get("/auth/fakeLogout")
      .then(() => {
        localStorage.removeItem("fakeUsername");
        localStorage.removeItem("fakeEmail");
        commit("SET_FAKEUSER", false);
      })
      .catch(error => { return { "error": error }; });
  },
  switchUser ({ commit }, payload) {
    if (payload) commit("SET_FAKEUSER", payload);
  }
};

const getters = {
  fakeUser: state => state.fakeUser
};

const fakeLoginModule = {
  state,
  mutations,
  actions,
  getters
};

export default fakeLoginModule;

<template>
  <v-dialog :value="value" v-if="form" persistent max-width="600px">
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md text-xs-center>
            <v-layout row wrap>
              <!-- start date -->
              <v-flex xs6 class="px-2">
                <date-selector
                  :id="'stayDateStart'+form.stay.key"
                  label="Date de début de séjour"
                  v-model="form.stay.start"
                  v-validate="'required|date_format:yyyy-MM-dd|before:'+form.stay.end+'|after:'+campaign.stay.dates.start"
                  name="stayDateStart"
                  :error="errors.first('stayDateStart')"
                  :min="campaign.stay.dates.start"
                  :max="campaign.stay.dates.end"
                />
              </v-flex>

              <!-- end date -->
              <v-flex xs6 class="px-2">
                <date-selector
                  :id="'stayDateEnd'+form.stay.key"
                  label="Date de fin de séjour"
                  v-model="form.stay.end"
                  v-validate="'required|date_format:yyyy-MM-dd|before:'+campaign.stay.dates.end+'|after:'+form.stay.start"
                  name="stayDateEnd"
                  :error="errors.first('stayDateEnd')"
                  :min="form.stay.start"
                  :max="campaign.stay.dates.end"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- close button -->
        <v-btn color="secondary" @click="close">Fermer</v-btn>
        <v-btn color="primary" @click.prevent="update">Modifier</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions } from "vuex";
import dateSelector from "@/components/utils/DateSelector.vue";

export default {
  props: ["value", "stay", "currentCampaign"],
  components: {
    dateSelector
  },
  data: () => ({
    form: null,
    campaign: null
  }),
  watch: {
    /**
     * Freeze the stay's information to be displayed on the widget
     */
    async value (newValue) {
      if (!newValue) {
        this.form = null;
        this.campaign = null;
        this.resetValidators();
      } else {
        this.form = JSON.parse(JSON.stringify(this.stay));
        this.form.stay.start = new Date(this.form.stay.start).toISOString().substr(0, 10);
        this.form.stay.end = new Date(this.form.stay.end).toISOString().substr(0, 10);

        this.campaign = JSON.parse(JSON.stringify(this.currentCampaign));
        this.campaign.stay.dates.start = new Date(this.campaign.stay.dates.start).toISOString().substr(0, 10);
        this.campaign.stay.dates.end = new Date(this.campaign.stay.dates.end).toISOString().substr(0, 10);
      }
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /** Close the form */
    close () {
      this.$emit("input", false);
    },
    /** Update stay */
    async update () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Vérifiez le séjour");

        const newDates = { start: this.form.stay.start, end: this.form.stay.end };
        await this.$axios.put(`/applicationManagement/${this.form.application}/stay/${this.form.stay.key}`, newDates);

        this.showSnackbar({
          text: "Le séjour a été modifié avec succes!",
          multiline: true,
          type: "success"
        });

        this.$emit("success", true);
        this.close();
      } catch (error) {
        this.showSnackbar({
          text: `Oups ! ${error.message}\n`,
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Function that fix the veeValidate bug when using v-if
     *
     * @see https://github.com/logaretm/vee-validate/issues/2109
     */
    resetValidators () {
      this.$validator.pause();
      this.$nextTick(() => {
        this.$validator.errors.clear();
        this.$validator.fields.items.forEach(field => field.reset());
        this.$validator.fields.items.forEach(field => this.errors.remove(field));
        this.$validator.resume();
      });
    }
  }
};
</script>

<template>
  <v-menu
    ref="dateMenu"
    v-model="showDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="fieldValue"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-layout row class="ui icon input">
        <v-text-field
          append-icon="event"
          hint="Format de la date: JJ-MM-AAAA"
          :id="id"
          :data-cy="dataCy"
          v-model="fieldValuee"
          :name="name"
          :label="label"
          :error-messages="error"
          @blur="submit"
          @click:append="showDatePicker = true"
          :disabled="disabled"
        />
      </v-layout>
    </template>
    <v-date-picker
      v-model="DatePickerValue"
      locale="fr"
      @input="submitDatePicker"
      :min="min"
      :max="max"
      scrollable
    />
  </v-menu>
</template>

<script>
export default {
  $_veeValidate: {
    name () { return this.name; },
    value () { return this.value; }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    error: {
      type: String
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    dataCy: {
      type: String
    }
  },
  data () {
    return {
      showDatePicker: false,
      fieldValue: this.value,
      DatePickerValue: this.value,
      fieldValuee: this.dateFormatFR(this.value)
    };
  },
  watch: {
    value (newValue) {
      this.fieldValuee = this.dateFormatFR(newValue);
      this.fieldValue = "";
      this.DatePickerValue = newValue;
    }
  },
  methods: {
    /**
     * Submit the date
     */
    async submit () {
      if (this.fieldValuee === "DatePicker") this.fieldValuee = await this.dateFormatFR(this.fieldValue);
      this.fieldValue = await this.dateFormatEN(this.fieldValuee);

      await this.$refs.dateMenu.save(this.fieldValue);
      await this.$emit("input", this.fieldValue);
      await this.$emit("success");
    },
    /**
     * Triggered when the user use "v-date-picker"
     */
    submitDatePicker () {
      this.fieldValue = this.DatePickerValue;
      this.fieldValuee = "DatePicker";
      this.submit();
    },
    /**
     * Update date format (YYYY-MM-DD => DD-MM-YYYY)
     */
    dateFormatFR (date) {
      var newDate = "";

      if (new Date(date).getDate() < 10) newDate = "0";
      newDate = newDate + (new Date(date).getDate()) + "-";
      if (new Date(date).getMonth() + 1 < 10) newDate = newDate + "0";
      newDate = newDate + (new Date(date).getMonth() + 1) + "-" + new Date(date).getFullYear();

      if (this.min && /^[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][1-9]$/.test(this.min) && (newDate === "NaN-NaN-NaN" || date < this.min)) return this.dateFormatFR(this.min);
      else if (newDate === "NaN-NaN-NaN") return this.dateFormatFR(this.today());

      return newDate;
    },
    /**
     * Update date format (DD-MM-YYYY => YYYY-MM-DD)
     */
    dateFormatEN (date) {
      var newDate = date.substring(6, 10) + date.substring(2, 6) + date.substring(0, 2);

      if (this.min && /^[0-3][1-9]-[0-1][0-9]-[0-9][0-9][0-9][0-9]$/.test(this.min) && (!/^[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][1-9]$/.test(newDate) || date < this.min)) return this.min;

      return newDate;
    }
  }
};
</script>

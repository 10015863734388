<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      v-if="user && user.email"
      disable-resize-watcher
      right
      clipped
      app
    >
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-tile to="/profile">
            <v-list-tile-action>
              <v-icon>person</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="font-weight-bold text-uppercase">{{ user.username }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>

      <v-list class="pt-0" dense>
        <v-divider></v-divider>
        <v-list-tile v-for="(item, id) in filteredMenu" :key="id" :to="item.url">
          <v-list-tile-action>
            <v-icon>chevron_right</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider></v-divider>
        <v-list-tile @click.prevent="logout">
          <v-list-tile-action>
            <v-icon>power_off</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>Déconnexion</v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar dense dark color="primary" fixed app clipped-right>
      <!-- Application name -->
      <v-toolbar-title>
        <router-link to="/" class="title">{{ title }}</router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- Application menu in normal displays-->
      <template v-if="user && user.email">
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn v-if="fakeUser && fakeUser.fakeEmail !== null" small fab flat color="error" @click.prevent="logoutFakeUser">
            <v-icon dark>power_settings_new</v-icon>
          </v-btn>
          <v-btn to="/profile" flat class="user-button" :color="fakeUser && fakeUser.fakeEmail !== null ? 'orange':'white'">
            <v-icon dark class="pr-1">{{fakeUser && fakeUser.fakeEmail !== null ? 'supervised_user_circle':'person'}}</v-icon>
            {{ user.username }}
          </v-btn>

          <template v-for="(item,id) of filteredMenu">
            <v-btn :id="item.id" :key="id" flat :to="item.url">{{item.title}}</v-btn>
          </template>

          <v-btn id="logout" :disabled="fakeUser && fakeUser.fakeEmail !== null" flat @click.prevent="logout">Déconnexion</v-btn>
        </v-toolbar-items>

        <!-- Application menu in mobile displays-->
        <v-toolbar-side-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      </template>

      <!-- Logout -->
      <v-toolbar-items v-else>
        <v-btn id="login" flat @click.prevent="login">Connexion</v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

const { VUE_APP_CAS_URL, VUE_APP_CAS_SERVICE, VUE_APP_URL } = process.env;

/**
 *  The navigation bar of the application
 */
export default {
  name: "NavBar",
  data () {
    return {
      drawer: false,
      menu: [
        { id: "campaign", title: "Campagnes", url: "/campaign", protected: false },
        { id: "application", title: "Dossiers", url: "/application", protected: false },
        { id: "management", title: "Gestion", url: "/management", protected: false },
        { id: "laboratory", title: "Laboratoires", url: "/laboratory", protected: true },
        { id: "user", title: "Utilisateurs", url: "/user", protected: true }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "fakeUser",
      "user", // username of the logged user
      "title" // Application name
    ]),
    /**
     * Check if user is a bred member
     */
    isBRED () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "BRED"));
    },
    /**
     * Check if user is an administrator
     */
    isADMIN () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "ADMIN"));
    },
    filteredMenu () {
      if (this.isBRED || this.isADMIN) {
        return this.menu;
      }
      return this.menu.filter(m => !m.protected);
    }
  },
  methods: {
    ...mapActions(["fakeLogin"]),
    /**
     * Open the CAS login page
     */
    login () {
      window.open(`${VUE_APP_CAS_URL}/login?service=${VUE_APP_CAS_SERVICE}/login`, "_self");
    },
    /**
     *  Logout the username
     */
    logout () {
      this.$store.dispatch("logout")
        .then(() => {
          window.open(`${VUE_APP_CAS_URL}/logout?url=${VUE_APP_URL}`, "_self");
        });
    },
    async logoutFakeUser () {
      await this.$store.dispatch("fakeLogout");
      await this.login();
    }
  }
};
</script>

<style lang="scss">
a.title {
  color: white;
  text-decoration: none;
}
button.user-button.v-btn.v-btn--disabled {
  color: white !important;
  font-weight: bold;
}

.user-button.v-btn.v-btn--disabled i.v-icon {
  color: white !important;
}
</style>

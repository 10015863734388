<template>
  <v-container>
    <form>
      <v-stepper v-model="currentTab">
        <v-stepper-header>
          <v-stepper-step :complete="progress > 1" step="1" editable>Renseignements sur l'invitant</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="progress > 2"
            step="2"
            :editable="progress>1"
          >Renseignements sur le candidat</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :editable="progress>2">Curriculum Vitae</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <HostForm
              ref="hostForm"
              :campaignId="campaignId"
              :editApplicationId="editApplicationId"
              v-on:hostForm="hostForm"
              @close="$emit('close')"
            />
          </v-stepper-content>

          <v-stepper-content step="2">
            <GuestForm
              ref="guestForm"
              :editApplicationId="editApplicationId"
              v-on:guestForm="guestForm"
              @close="$emit('close')"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <CVForm
              ref="cvForm"
              :editApplicationId="editApplicationId"
              v-on:CVForm="CVForm"
              @close="$emit('close')"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </form>
    <v-dialog :value="loading" max-width="500px" :scrollable="false" persistent>
      <template>
        <v-card>
          <v-card-title
            class="headline primary--text"
          >Sauvegarde de votre dossier • Saving your application</v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs6>
                <span>{{ cardText }}</span>
              </v-flex>
              <v-flex xs6>
                <v-progress-circular :size="20" :width="2" color="primary" indeterminate></v-progress-circular>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>

import { mapActions } from "vuex";
import GuestForm from "@/components/application/stepper/GuestForm.vue";
import HostForm from "@/components/application/stepper/HostForm.vue";
import CVForm from "@/components/application/stepper/CVForm.vue";

export default {
  name: "ApplicationForm",
  props: {
    editApplicationId: {
      type: String
    },
    campaignId: {
      type: String
    }
  },
  data () {
    return {
      form: {
        guest: {
          identity: {
            gender: "",
            firstName: "",
            lastName: "",
            birthDate: this.today(),
            email: "",
            nationality: "",
            nativeCountry: "",
            position: ""
          },
          professionalAddress: {
            country: "",
            city: "",
            institution: ""
          },
          alreadyInvited: null,
          researchActivities: ""
        },
        host: "",
        stayDate: {
          start: this.today(),
          end: this.today()
        },
        project: {
          title: "",
          summary: ""
        },
        transportationCost: "",
        photoVisible: "",
        type: "",
        keywords: "",
        status: "waiting",
        campaign: "",
        vote: {
          adhoc: {
            days: 0,
            transportationCost: 0,
            remark: ""
          },
          research: {
            days: 0,
            transportationCost: 0,
            remark: ""
          }
        }
      },
      applicationId: "",
      photoFile: "",
      cvFile: "",
      projectFile: "",
      cardText: "",

      progress: 0,
      currentTab: 0,
      loading: false
    };
  },
  components: {
    GuestForm,
    HostForm,
    CVForm
  },
  watch: {
    editApplicationId (newId) {
      if (newId) return;
      this.clearForm();
    }
  },
  mounted () {
    this.loggedIn();
  },
  methods: {
    ...mapActions(["showSnackbar"]),

    /**
     * Check if the user session is not expired
     */
    loggedIn () {
      this.$axios.get("auth/loggedin")
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! il semble que votre session ait expirée :(",
            type: "error",
            multiline: true
          });
          this.$store.dispatch("logout")
            .then(() => this.$router.push({ name: "home" }));
        });
    },
    /**
     * Save application into the database
     */
    async saveApplication () {
      try {
        this.form.campaign = this.campaignId;
        const application = await this.$axios.post("/application", this.form);
        this.applicationId = application.data._id;
      } catch (error) {
        throw new Error({ message: error });
      }
    },
    /**
     * Update an application
     */
    async updateApplication () {
      try {
        const application = await this.$axios.put("/application/" + this.editApplicationId, this.form);
        this.applicationId = application.data._id;
      } catch (error) {
        throw new Error({ message: error });
      }
    },
    /**
     * Delete an application
     */
    deleteApplication () {
      try {
        this.$axios.delete(`/application/${this.applicationId}`);
      } catch (error) {
        throw new Error({ message: error });
      }
    },
    /**
     * Upload cv and photo of the application
     */
    uploadDocuments () {
      try {
        if (!this.photoFile && !this.cvFile && !this.projectFile) return;
        const formData = new FormData();
        if (this.photoFile) formData.append("photo", this.photoFile);
        if (this.cvFile) formData.append("cv", this.cvFile);
        if (this.projectFile) formData.append("project", this.projectFile);
        this.$axios.put(`/application/upload/${this.applicationId}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
      } catch (error) {
        throw new Error({ message: error });
      }
    },
    /**
     * Set guest form
     * @param {JSON} form - guest form
     */
    guestForm (form) {
      this.form.guest = form.guest;
      this.progress = 3;
      this.currentTab = 3;
    },
    /**
     * Set host form
     * @param {JSON} host - host form
     * @param {JSON} stayDate - application stay dates
     * @param {JSON} project - application project title and project description
     * @param {number} transportationCost - application transportation cost
     * @param {string} type - application type
     */
    hostForm (stayDate, project, transportationCost, type, campaign, projectFile) {
      this.form.stayDate = stayDate;
      this.form.project = project;
      this.form.transportationCost = transportationCost;
      this.form.type = type;
      this.form.campaign = campaign;
      this.progress = 2;
      this.currentTab = 2;
      this.projectFile = projectFile;
    },
    /**
     * Set CV form
     * @param {JSON} guest - application informations
     * @param {string} type - request type (create or update)
     */
    CVForm (guest, type) {
      this.form.keywords = guest.keywords;
      this.form.photoVisible = guest.photoVisible;
      this.form.guest.researchActivities = guest.researchActivities;
      this.photoFile = guest.photo;
      this.cvFile = guest.cv;
      this.submit(type);
    },
    /**
     * Submit the form
     */
    async submit (type) {
      try {
        this.loggedIn();
        this.loading = true;
        if (type === "create") {
          this.cardText = "Sauvegarde du dossier";
          await this.saveApplication();
        } else {
          this.cardText = "Mise à jour du dossier";
          await this.updateApplication();
        }
        await this.sleep(2000);
        await this.uploadDocuments();
        this.loading = false;
        this.showSnackbar({
          text: "Parfait !",
          multiline: true,
          type: "success"
        });
        this.$emit("success");
        this.$emit("close");
        if (type === "create") this.clearForm();
        else this.clearSettings();
      } catch (error) {
        if (this.applicationId) this.deleteApplication();
        this.loading = false;
        this.showSnackbar({
          text: "Oups !\nUne erreur est survenue",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Sleep time between requests from client to server
     */
    sleep (milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },
    /**
     * Clear form fields
     */
    clearForm () {
      this.$refs.guestForm.clearForm();
      this.$refs.hostForm.clearForm();
      this.$refs.cvForm.clearForm();
      this.form.guest.identity.gender = "";
      this.form.guest.identity.firstName = "";
      this.form.guest.identity.lastName = "";
      this.form.guest.identity.birthDate = this.today();
      this.form.guest.identity.email = "";
      this.form.guest.identity.nationality = "";
      this.form.guest.identity.nativeCountry = "";
      this.form.guest.identity.position = "";
      this.form.guest.identity.institution = "";
      this.form.guest.professionalAddress.country = "";
      this.form.guest.professionalAddress.city = "";
      this.form.guest.alreadyInvited = null;
      this.form.guest.researchActivities = "";

      this.form.stayDate.start = this.today();
      this.form.stayDate.end = this.today();

      this.form.project.title = "";
      this.form.project.summary = "";

      this.form.transportationCost = "";
      this.form.photoVisible = "";
      this.form.type = "";
      this.form.keywords = "";

      this.clearSettings();
    },
    /**
     * Clear settings
     */
    clearSettings () {
      this.photoFile = "";
      this.cvFile = "";

      this.applicationId = "";

      this.cardText = "";
      this.progress = 1;
      this.currentTab = 1;
    }
  }
};
</script>

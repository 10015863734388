<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-5">
      <!-- title -->
      <h1 class="display-2">Dossiers Accéptés ({{user.institution.laboratory.acronym}})</h1>
    </v-flex>
    <v-flex xs12 class="mb-2">
      <v-card>
        <!-- search textfield -->
        <v-card-title>
          <h1>Invitants</h1>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Rechercher"
            single-line
            hide-details
          />
        </v-card-title>

        <!-- host table -->
        <v-data-table hide-actions :headers="headers" :items="applicationsInfo" :search="search">
          <template v-slot:items="props">
            <td class="text-xs-center">{{props.item.host.lastName.toUpperCase()}}</td>
            <td class="text-xs-center">{{props.item.host.firstName}}</td>
            <td class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="showForm(props.item)">edit</v-icon>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-dialog v-if="selected" v-model="showDialog" hide-overlay fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{selected.host.firstName}} {{selected.host.lastName}}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="closeForm">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <Form :applicationId="selected._id" @submitForm="submitForm" />
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "@/components/applicationManagement/Form.vue";

export default {
  name: "ApplicationsViewer",
  components: { Form },
  props: ["applications"],
  data: () => ({
    search: "",
    selected: null,
    showDialog: false,
    headers: [
      { text: "Nom", value: "host.lastName", align: "center" },
      { text: "Prénom", value: "host.firstName", align: "center" },
      { text: "Actions", value: "actions", align: "center", sortable: false }
    ]
  }),
  computed: {
    ...mapGetters(["user"]),
    applicationsInfo () {
      return this.applications.map(app => app.application);
    }
  },
  methods: {
    showForm (application) {
      this.selected = application;
      this.showDialog = true;
    },
    closeForm () {
      this.selected = null;
      this.showDialog = false;
    },
    async submitForm (form) {
      try {
        const id = form._id;
        const bankInformation = form.bankInformation;
        form._id = undefined;
        form.bankInformation = undefined;

        await this.$axios.put("/applicationManagement/" + id, form);

        if (bankInformation instanceof File) {
          const formData = new FormData();
          formData.append("rib", bankInformation);
          await this.$axios.put(`/applicationManagement/upload/rib/${this.selected._id}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        }

        location.reload();
      } catch (error) {
        console.log("%c⧭", "color: #1d5673", error);
      }
    }
  }
};
</script>

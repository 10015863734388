import axios from "@/axios";

const state = {
  user: {
    username: localStorage.getItem("username") || null,
    firstName: localStorage.getItem("firstName") || null,
    lastName: localStorage.getItem("lastName") || null,
    email: localStorage.getItem("email") || null,
    gender: localStorage.getItem("gender") || null,
    institution: JSON.parse(localStorage.getItem("institution")) || null,
    roles: JSON.parse(localStorage.getItem("roles")) || []
  }
};

/**
 * Function that creates an user object
 *
 * @param {Object} user
 *
 * @return {Object} - an user object
 */
function createUser (user = {}) {
  return { username: user.username, firstName: user.firstName, lastName: user.lastName, email: user.email, gender: user.gender, institution: user.institution, roles: [...user.roles] };
}

const mutations = {
  SET_USER (state, user) {
    if (user && user.username) {
      state.user = createUser(user);
    } else {
      state.user = null;
    }
  },
  SET_USERNAME (state, user) {
    state.user.username = user.username;
  }
};

const actions = {
  login ({ commit }) {
    return axios.get("/auth/session")
      .then(result => {
        if (result.data.email) {
          localStorage.setItem("username", result.data.username);
          localStorage.setItem("firstName", result.data.firstName);
          localStorage.setItem("lastName", result.data.lastName);
          localStorage.setItem("email", result.data.email);
          localStorage.setItem("gender", result.data.gender);
          localStorage.setItem("institution", JSON.stringify(result.data.institution));
          localStorage.setItem("roles", JSON.stringify(result.data.roles));
          commit("SET_USER", result.data);
        } else {
          localStorage.setItem("username", result.data.username);
          commit("SET_USERNAME", result.data);
        }
      })
      .catch(error => { return { "error": error }; });
  },
  logout ({ commit }) {
    return axios.get("/auth/logout")
      .then(() => {
        localStorage.removeItem("username");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("gender");
        localStorage.removeItem("institution");
        localStorage.removeItem("roles");
        commit("SET_USER", null);
      })
      .catch(error => { return { "error": error }; });
  },
  switchUser ({ commit }, payload) {
    if (payload) commit("SET_USER", payload);
  }
};

const getters = {
  user: state => state.user
};

const loginModule = {
  state,
  mutations,
  actions,
  getters
};

export default loginModule;

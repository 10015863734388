import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Campaigns from "./views/Campaigns.vue";
import Application from "./views/Application.vue";
import Profile from "./views/Profile.vue";
import NotFound from "./views/NotFound.vue";
import loginStore from "./store/modules/login";
import Laboratory from "./views/Laboratory.vue";
import User from "./views/User.vue";
import ApplicationManagement from "./views/ApplicationManagement.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/campaign",
      name: "campaign",
      component: Campaigns
    },
    {
      path: "/application",
      name: "application",
      component: Application
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/management",
      name: "management",
      component: ApplicationManagement
    },
    {
      path: "/laboratory",
      name: "laboratory",
      component: Laboratory,
      meta: {
        requiresAuth: true,
        allowedRoles: ["BRED", "ADMIN"]
      }
    },
    {
      path: "/user",
      name: "user",
      component: User,
      meta: {
        requiresAuth: true,
        allowedRoles: ["BRED", "ADMIN"]
      }
    },
    {
      path: "*",
      name: "404",
      component: NotFound
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loginStore.state.user.username) {
      next({ name: "home" });
    }
  }

  if (to.matched.some(record => record.meta.allowedRoles)) {
    const allowedRoles = to.matched[0].meta.allowedRoles;
    const userRoles = loginStore.state.user.roles;

    const userHasRole = allowedRoles.some(a => userRoles.find(item => item.name === a));
    if (!userHasRole) {
      next({ name: "home" });
    }
  }

  next();
});

export default router;

import VeeValidate, { Validator } from "vee-validate";

const dict = {
  custom: {
    acronym: {
      required: () => "L'acronym est obligatoire"
    },
    beneficiaryBankAddress: {
      required: () => "Veuillez entrer l'adresse"
    },
    beneficiaryBankBIC: {
      required: () => "Veuillez entrer votre BIC (Bank Identifier Code) ou SWIFT"
    },
    beneficiaryBankName: {
      required: () => "Veuillez entrer le nom de la banque"
    },
    cv: {
      require: () => "Veuillez entrer un cv."
    },
    datesCampaignDeadline: {
      required: () => "Votre date limite de dépôt de dossiers est vide.",
      after: (name, params) => "Votre date limite de dépôt de dossiers doit être après l'ouverture de la campagne (" + dateFormatFR(params) + ")",
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    datesCampaignOpening: {
      required: () => "Votre date d'ouverture de la campagne est vide.",
      before: (name, params) => "Votre ouverture de campagne doit être avant la date limite de dépôt de dossiers (" + dateFormatFR(params) + ")",
      after: (name, params) => "Votre date d'ouverture de campagne doit être après la publication de l'appel d'offre (" + dateFormatFR(params) + ")",
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    datesCampaignPublication: {
      required: () => "Votre date de publication de l'appel d'offre est vide.",
      before: (name, params) => "Votre date de publication de l'appel d'offre doit être avant ouverture de la campagne (" + dateFormatFR(params) + ")",
      after: (name, params) => "Votre date de publication de l'appel d'offre doit être après le " + dateFormatFR(params),
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    datesCommitteeAdHoc: {
      required: () => "Votre date de la commission Ad Hoc est vide.",
      before: (name, params) => "Votre date de la commission Ad Hoc doit être avant la date de validation par la CR (" + dateFormatFR(params) + ")",
      after: (name, params) => "Votre date de la commission Ad Hoc doit être après la date limite de dépôt de dossiers  " + dateFormatFR(params),
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    datesCommitteeResearch: {
      required: () => "Votre date de validation par la CR est vide.",
      after: (name, params) => "Votre date de validation par la CR doit être après la date de la commission Ad Hoc (" + dateFormatFR(params) + ")",
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    email: {
      required: () => "Veuillez entrer un email.",
      email: () => "L'email n'est pas valide"
    },
    firstName: {
      required: () => "Veuillez entrer un prénom.",
      regex: () => "Le prénom doit être composé de lettre ou d'espaces"
    },
    gender: {
      required: () => "Veuillez choisir un genre."
    },
    guestAlreadyInvited: {
      required: () => "Veuillez entrer la date.",
      before: (name, params) => "Cette date doit être avant le " + dateFormatFR(params)
    },
    guestArticle: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestBirthDate: {
      required: () => "Veuillez entrer le date de naissance.",
      before: (name, params) => "Votre date de naissance doit être avant le " + dateFormatFR(params)
    },
    guestCertificate: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestCity: {
      required: () => "Veuillez entrer la ville."
    },
    guestConference: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestCountry: {
      required: () => "Veuillez entrer le pays."
    },
    guestEducation: {
      required: () => "Veuillez choisir une option."
    },
    guestEducationInstitution: {
      required: () => "Veuillez entrer l'établissement d'obtention."
    },
    guestEducationYear: {
      required: () => "Veuillez entrer l'année d'obtention."
    },
    guestInstitution: {
      required: () => "Veuillez entrer votre établissement d'origine."
    },
    guestNationality: {
      required: () => "Veuillez entrer votre nationalité."
    },
    guestNativeCountry: {
      required: () => "Veuillez entrer votre pays de naissance."
    },
    guestOtherDescription: {
      required: () => "Veuillez écrire une déscription."
    },
    guestOtherQuantity: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestPopular: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestPosition: {
      required: () => "Veuillez entrer votre position."
    },
    guestPostalCode: {
      required: () => "Veuillez entrer le code Postal."
    },
    guestReport: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestSeminar: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestSymposium: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    guestType: {
      required: () => "Veuillez choisir une option."
    },
    guestWork: {
      decimal: () => "Veuillez entrer des chiffres."
    },
    hostLaboratory: {
      required: () => "Veuillez entrer le laboratoire."
    },
    hostResearchTeam: {
      required: () => "Veuillez entrer l'équipe de recherche."
    },
    hostSummary: {
      required: () => "Veuillez entrer le résumé du projet de recherche."
    },
    hostTitle: {
      required: () => "Veuillez entrer le titre du projet."
    },
    hostTransportationCost: {
      required: () => "Veuillez entrer l'estimation des frais de transports en euros.",
      min_value: () => "L'estimation des frais de transports en euros doit être supérieur ou égal à 0"
    },
    lastName: {
      required: () => "Veuillez entrer un nom de famille.",
      regex: () => "Le nom de famille actuel doit être composé de lettre ou d'espaces"
    },
    maxBudget: {
      required: () => "Veuillez entrer un budget maximum.",
      min_value: () => "Le budget maximum doit être supérieur à 0"
    },
    photo: {
      require: () => "Veuillez entrer choisir une photo."
    },
    president: {
      required: () => "Votre champ président actuel est vide.",
      regex: () => "Votre champ président actuel doit être composé de lettre ou d'espaces"
    },
    researchActivities: {
      require: () => "Veuillez entrer votre activité de recherche."
    },
    stayAmountPerDay: {
      required: () => "Votre montant par jour est vide.",
      min_value: () => "Votre montant par jour doit être supérieur ou égal à 0"
    },
    stayNumberDaysMaximum: {
      required: () => "Votre nombre de jour maximum est vide.",
      min_value: (name, params) => "Votre nombre de jour maximum doit être supérieur au nombre de jour minimum (" + dateFormatFR(params) + ")"
    },
    stayNumberDaysMinimum: {
      required: () => "Votre nombre de jour minimum est vide.",
      min_value: () => "Votre nombre de jour minimum doit être supérieur ou égal à 0",
      max_value: (name, params) => "Votre nombre de jour minimum doit être inférieur ou égal au nombre de jour maximum (" + dateFormatFR(params) + ")"
    },
    stayDatesEnd: {
      required: () => "Votre date de fin des séjours est vide.",
      before: (name, params) => "Votre date de fin des séjours doit être avant le " + dateFormatFR(params),
      after: (name, params) => "Votre date de fin des séjours doit être après la date de début des séjours (" + dateFormatFR(params) + ")",
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    stayDatesStart: {
      required: () => "Votre date de début des séjours est vide.",
      before: (name, params) => "Votre date de début des séjours doit être avant la date de fin des séjours (" + dateFormatFR(params) + ")",
      after: (name, params) => "Votre date de début des séjours doit être après le " + dateFormatFR(params),
      date_format: () => "Veuillez respecter le format (JJ/MM/AAAA)"
    },
    stayNumberTravels: {
      required: () => "Votre nombre de voyage autorisé est vide.",
      min_value: () => "Votre nombre de voyage autorisé doit être supérieur ou égal à 1"
    },
    votedDays: {
      required: () => "Le nombre de jours voté est vide",
      min_value: () => "Le nombre de jours voté ne peut pas être inférieur à 0"
    },
    votedFees: {
      required: () => "L'estimation des frais de transports en euros voté est vide",
      min_value: () => "L'estimation des frais de transports en euros voté ne peut pas être inférieur à 0"
    }
  }
};

// TODO: remove this and use the momentjs library
/**
 * Format a date with the french format
 *
 * @param {string} date - string representing a date
 * @return string of the new formatted date
 */
function dateFormatFR (date) {
  let dateDay = new Date(date).getDate();
  let dateMonth = new Date(date).getMonth() + 1;
  let dateYear = new Date(date).getFullYear();

  dateDay = (dateDay < 10) ? "0" + dateDay : dateDay;
  dateMonth = (dateMonth < 10) ? "0" + dateMonth : dateMonth;

  const newDate = `${dateDay}-${dateMonth}-${dateYear}`;

  if (newDate === "NaN-NaN-NaN") return "";

  return newDate;
};

Validator.localize("fr", dict);

export default VeeValidate;

<template>
  <v-card>
    <v-toolbar color="primary" card dark>
      <v-toolbar-title>Commentaires</v-toolbar-title>
    </v-toolbar>

    <v-progress-linear v-if="loading" indeterminate class="mb-0" />

    <v-card-text v-else>
      <v-form ref="form">
        <v-container class="py-0">
          <!-- Dossier remarks -->
          <v-layout row wrap>
            <v-flex xs12>
              <h2 class="primary--text">Information Dossier :</h2>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                id="hostDays"
                label="Nombre de jours"
                v-model="applicationNbrDays"
                name="hostDays"
                disabled
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="form.transportationCost"
                label="Estimation des frais de transports en euros"
                suffix="€"
                v-validate="'required|min_value:0'"
                name="hostTransportationCost"
                id="hostTransportationCost"
                :error-messages="errors.collect('hostTransportationCost')"
                disabled
              ></v-text-field>
            </v-flex>
          </v-layout>

          <!-- Ad-hoc remarks -->
          <v-layout row wrap>
            <v-flex xs12>
              <h2 class="primary--text">Commission AD-HOC :</h2>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="form.vote.adhoc.days"
                label="Nombre de jours"
                v-validate="'required|min_value:0'"
                name="AdHocVotedDays"
                :error-messages="errors.collect('AdHocVotedDays')"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="form.vote.adhoc.transportationCost"
                label="Estimation des frais de transports en euros"
                suffix="€"
                name="AdHocVotedFees"
                :error-messages="errors.collect('AdHocVotedFees')"
                v-validate="'required|min_value:0'"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="pt-0">
              <v-textarea
                v-model="form.vote.adhoc.remark"
                label="Commentaire"
                name="AdHocRemarks"
                :error-messages="errors.collect('AdHocRemarks')"
                class="pt-0"
              ></v-textarea>
            </v-flex>
          </v-layout>

          <!-- CR remarks -->
          <v-layout row wrap>
            <v-flex xs12>
              <h2 class="primary--text">Commission Recherche :</h2>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="form.vote.research.days"
                label="Nombre de jours"
                name="researchVotedDays"
                :error-messages="errors.collect('researchVotedDays')"
                v-validate="'required|min_value:0'"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="form.vote.research.transportationCost"
                label="Estimation des frais de transports en euros"
                suffix="€"
                name="researchVotedFees"
                :error-messages="errors.collect('researchVotedFees')"
                v-validate="'required|min_value:0'"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="pt-0">
              <v-textarea
                v-model="form.vote.research.remark"
                label="Commentaire"
                name="researchRemarks"
                :error-messages="errors.collect('researchRemarks')"
                class="pt-0"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider class="my-2" />
    <v-card-actions>
      <v-spacer />
      <v-btn color="secondary" id="closeBtn" @click="close">Fermer</v-btn>
      <v-btn color="primary" @click.prevent="submit">Envoyer</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

function emptyApplication () {
  return {
    transportationCost: 0,
    vote: {
      adhoc: {
        days: 0,
        transportationCost: 0,
        remark: ""
      },
      research: {
        days: 0,
        transportationCost: 0,
        remark: ""
      }
    }
  };
}

export default {
  name: "CommentaryForm",
  computed: {
    ...mapGetters([
      "user" // username of the logged user
    ])
  },
  props: {
    id: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    form: emptyApplication(),
    applicationNbrDays: 1
  }),
  watch: {
    async id (newId) {
      if (!newId) return;
      await this.fetchApplication(newId);
    }
  },
  async mounted () {
    if (!this.id) return;
    await this.fetchApplication(this.id);
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    close () {
      this.form = emptyApplication();
      this.applicationNbrDays = 1;
      this.$emit("close");
    },
    sucess () {
      this.$emit("success");
      this.close();
    },
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        await this.$axios.put("/application/vote/" + this.id, { vote: this.form.vote });
        await this.showSnackbar({
          text: "Parfait! Commentaire ajouté avec succès",
          type: "success",
          multiline: true
        });
        this.sucess();
      } catch (error) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Fetch host form when edit application
     */
    async fetchApplication (id) {
      try {
        this.loading = true;

        const response = await this.$axios.get("/application/" + id);
        this.form = response.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    }
  }
};

</script>

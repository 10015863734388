const state = {
  visible: false,
  text: null,
  multiline: false,
  timeout: 3000,
  type: "info"
};

const mutations = {
  SET_TEXT (state, text) {
    state.text = text;
  },
  SET_VISIBILITY (state, visibility) {
    state.visible = visibility;
  },
  SET_TIMEOUT (state, timeout) {
    state.timeout = timeout;
  },
  SET_TYPE (state, type) {
    state.type = type;
  },
  SET_MULTILINE (state, multiline) {
    state.multiline = multiline;
  }
};

const actions = {
  showSnackbar ({ commit }, payload) {
    if (payload.timeout) { commit("SET_TIMEOUT", payload.timeout); }
    if (payload.multiline) { commit("SET_MULTILINE", payload.multiline); }
    if (payload.type) { commit("SET_TYPE", payload.type); }

    commit("SET_TEXT", payload.text);
    commit("SET_VISIBILITY", true);
  },
  closeSnackbar ({ commit }) {
    commit("SET_VISIBILITY", false);
    commit("SET_TEXT", null);
    commit("SET_TIMEOUT", 3000);
    commit("SET_TYPE", "info");
  }
};

const getters = {
  snackbarVisible: state => state.visible
};

const snackbar = {
  state,
  mutations,
  actions,
  getters
};

export default snackbar;

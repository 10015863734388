<template>
  <v-form ref="form">
    <v-container class="py-0">
      <h1 class="primary--text">IV. Renseignements sur le candidat • Candidate Information:</h1>
      <h3>1) Identité de l'invité • Guest identity</h3>

      <v-layout row wrap>
        <v-flex xs3>
          <v-autocomplete
            id="guestGender"
            v-validate="'required'"
            name="gender"
            :error-messages="errors.collect('gender')"
            label="Sexe • Sex"
            v-model="form.guest.identity.gender"
            :items="civ"
            :menu-props="{maxHeight:'400'}"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs3>
          <v-text-field
            id="guestLastName"
            label="Nom • Last name"
            v-model="form.guest.identity.lastName"
            name="lastName"
            v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
            :error-messages="errors.collect('lastName')"
          ></v-text-field>
        </v-flex>

        <v-flex xs3>
          <v-text-field
            id="guestFirstName"
            label="Prénom • First Name"
            v-model="form.guest.identity.firstName"
            v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
            name="firstName"
            :error-messages="errors.collect('firstName')"
          ></v-text-field>
        </v-flex>

        <v-flex xs3>
          <v-text-field
            id="guestEmail"
            label="E-mail"
            v-model="form.guest.identity.email"
            v-validate="'required|email'"
            name="email"
            :error-messages="errors.collect('email')"
            dd
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs3>
          <date-selector
            label="Date de naissance • Birth date"
            v-model="form.guest.identity.birthDate"
            v-validate="'required|date_format:yyyy-MM-dd|before:'+maxDate"
            id="guestBirthDate"
            name="guestBirthDate"
            :error="errors.first('guestBirthDate')"
            :max="maxDate"
          />
        </v-flex>

        <v-flex xs3>
          <v-autocomplete
            id="guestNationality"
            :items="nationalities"
            v-model="form.guest.identity.nationality"
            label="Nationalité • Nationality"
            v-validate="'required'"
            name="guestNationality"
            :error-messages="errors.collect('guestNationality')"
            @input="form.guest.identity.nativeCountry=form.guest.identity.nationality"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs3>
          <v-autocomplete
            id="guestNativeCountry"
            :items="nationalities"
            v-model="form.guest.identity.nativeCountry"
            label="Pays de naissance • Native country"
            v-validate="'required'"
            name="guestNativeCountry"
            :error-messages="errors.collect('guestNativeCountry')"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs3>
          <v-autocomplete
            id="guestPosition"
            :items="poste"
            v-model="form.guest.identity.position"
            label="Poste actuel • Current position"
            v-validate="'required'"
            name="guestPosition"
            :error-messages="errors.collect('guestPosition')"
          ></v-autocomplete>
        </v-flex>
      </v-layout>

      <v-layout align-center>
        <v-flex xs4>
          <v-subheader class="pl-0" v-text="'Déjà invité par USPN • Already invited by USPN'" />
        </v-flex>

        <v-flex xs2>
          <v-radio-group v-model="alreadyInvited" name="guestAlreadyInvited" row>
            <v-radio id="alreadyInvitedOui" label="oui" :value="true"></v-radio>
            <v-radio id="alreadyInvitedNon" label="non" :value="false"></v-radio>
          </v-radio-group>
        </v-flex>

        <v-flex xs6 v-show="alreadyInvited">
          <v-text-field
            id="guestAlreadyInvited"
            label="Précisez l'année • Specify the year"
            v-model="form.guest.alreadyInvited"
            v-validate="'required_if:guestAlreadyInvited'"
            name="guestAlreadyInvited"
            :error="errors.first('guestAlreadyInvited')"
            type="number"
          />
        </v-flex>
      </v-layout>

      <h3>2) Adresse professionnelle complète • Full professional address</h3>
      <v-layout row wrap>
        <v-flex xs4>
          <v-autocomplete
            :items="countries"
            v-model="form.guest.professionalAddress.country"
            label="Pays • Country"
            v-validate="'required'"
            name="guestCountry"
            id="guestCountry"
            :error-messages="errors.collect('guestCountry')"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs4>
          <v-autocomplete
            :items="cities"
            v-model="form.guest.professionalAddress.city"
            label="Ville • City"
            v-validate="'required'"
            name="guestCity"
            id="guestCity"
            :error-messages="errors.collect('guestCity')"
            hint="Choisissez un pays en premier • Choose a country first"
            persistent-hint
          ></v-autocomplete>
        </v-flex>

        <v-flex xs4>
          <v-text-field
            label="Établissement d'origine • institution"
            v-model="form.guest.professionalAddress.institution"
            v-validate="'required'"
            id="guestInstitution"
            name="guestInstitution"
            :error-messages="errors.collect('guestInstitution')"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>

    <v-divider class="my-2"></v-divider>
    <v-layout row>
      <v-spacer />
      <v-btn color="primary" @click.prevent="submit">Continuer</v-btn>
      <v-btn color="secondary" :id="'closeBtn'" @click="$emit('close')">Fermer</v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import countries from "@/data/Countries_and_cities.json";
import dateSelector from "@/components/utils/DateSelector.vue";

export default {
  name: "GuestForm",
  components: {
    dateSelector
  },
  props: {
    editApplicationId: {
      type: String
    }
  },
  data () {
    return {
      maxDate: this.today(),
      form: {
        guest: {
          identity: {
            gender: "",
            firstName: "",
            lastName: "",
            birthDate: "",
            email: "",
            nationality: "",
            nativeCountry: "",
            position: ""
          },
          professionalAddress: {
            country: "",
            city: "",
            institution: ""
          },
          alreadyInvited: null
        }
      },
      alreadyInvited: false,
      civ: [
        "F",
        "M"
      ],
      countries: [],
      nationalities: [],
      poste: [
        "Professeur", "Chercheur", "Maître de conferences"
      ],
      years: []
    };
  },
  watch: {
    editApplicationId (newId) {
      if (!newId) return;
      this.fetchGuestForm();
    },
    alreadyInvited (value) {
      if (value) return;
      this.form.guest.alreadyInvited = null;
    }
  },
  mounted () {
    for (let index = 0; index < 50; index++) {
      this.years.push(Number(this.currentYear()) - index);
    }

    for (const country in countries) {
      if (country !== "France") this.countries.push(country);
      this.nationalities.push(country);
    }

    if (!this.editApplicationId) return;
    this.fetchGuestForm();
  },

  computed: {
    /**
     * Add all the cities in the cities array from the selected country
     */
    cities () {
      const selectedCountry = this.form.guest.professionalAddress.country;
      if (selectedCountry === "") return [];

      return countries[selectedCountry];
    }
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");
        this.$emit("guestForm", this.form);
      } catch (error) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Clear form fields
     */
    clearForm () {
      this.form.guest.identity.gender = "";
      this.form.guest.identity.firstName = "";
      this.form.guest.identity.lastName = "";
      this.form.guest.identity.birthDate = this.today();
      this.form.guest.identity.email = "";
      this.form.guest.identity.nationality = "";
      this.form.guest.identity.nativeCountry = "";
      this.form.guest.identity.position = "";
      this.form.guest.identity.institution = "";
      this.form.guest.professionalAddress.country = "";
      this.form.guest.professionalAddress.city = "";
      this.form.guest.alreadyInvited = null;
    },
    /**
     * Fetch guest form when edit application
     */
    async fetchGuestForm () {
      await this.$axios.get("/application/" + this.editApplicationId)
        .then((response) => {
          this.form = response.data;
          this.form._id = undefined;
          this.form.guest.identity.birthDate = response.data.guest.identity.birthDate.substr(0, 10);

          const dateInvitedBefore = this.form.guest.alreadyInvited;
          const parsedYear = dateInvitedBefore && (new Date(dateInvitedBefore)).getFullYear();

          // backward compatibility with full date
          if (parsedYear && (dateInvitedBefore.toString().length !== 4)) {
            this.form.guest.alreadyInvited = (parsedYear > 1) ? parsedYear : null;
          }

          this.alreadyInvited = this.form.guest.alreadyInvited !== null;
        })
        .catch((err) => console.log(err));
    }

  }
};
</script>

<template>
  <v-card>
    <!-- Form with information of the new laboratory -->
    <v-card-text>
      <v-form ref="form">
        <v-container grid-list-md>
          <v-layout row>
            <v-spacer></v-spacer>
            <!-- Acronym -->
            <v-flex xs3>
              <v-text-field
                :id="'acronym'+label"
                label="Acronym"
                v-model="form.acronym"
                v-validate="'required'"
                name="acronym"
                :error-messages="errors.collect('acronym')"
                required
              />
            </v-flex>
            <v-spacer></v-spacer>
          </v-layout>
          <p class="primary--text title">Directeur:</p>
          <v-layout row>
            <!-- The current director -->
            <v-flex xs4>
              <v-text-field
                :id="'directorLastName'+label"
                label="Nom"
                v-model="form.director.lastName"
                v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                name="lastName"
                :error-messages="errors.collect('lastName')"
                placeholder="Doe"
                required
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                :id="'directorFirstName'+label"
                label="Prénom"
                v-model="form.director.firstName"
                v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                name="firstName"
                :error-messages="errors.collect('firstName')"
                placeholder="John"
                required
              />
            </v-flex>
            <!-- director's email -->
            <v-flex xs4>
              <v-text-field
                v-model="form.director.email"
                label="E-mail"
                v-validate="'required|email'"
                name="email"
                id="directorEmail"
                :error-messages="errors.collect('email')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider class="mt-1"></v-divider>
    <v-card-actions>
      <v-spacer />
      <!-- close button -->
      <v-btn color="secondary" :id="'closeBtn'+label" @click="close">Fermer</v-btn>
      <!-- Submit button -->
      <v-btn
        v-if="!id"
        :id="'submitCreate'+label"
        :disabled="snackbarVisible"
        color="primary"
        @click.prevent="submit"
      >Ajouter</v-btn>
      <v-btn
        v-else
        :id="'submitUpdate'+label"
        :disabled="snackbarVisible"
        color="primary"
        @click.prevent="update"
      >Modifier</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    }
  },
  data: () => ({
    form: {
      acronym: "",
      director: {
        firstName: "",
        lastName: "",
        email: ""
      }
    }
  }),
  computed: {
    ...mapGetters(["snackbarVisible"])
  },
  watch: {
    id (newId) {
      if (!newId) return;
      this.fetchLaboratory();
    }
  },
  mounted () {
    if (!this.id) return;
    this.fetchLaboratory();
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        await this.$axios.post("/laboratory", this.form);
        this.showSnackbar({
          text: "Parfait !\nLe laboratoire a été créé avec succes!",
          multiline: true,
          type: "success"
        });
        this.success();
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     *Update laboratory
     */
    async update () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        await this.$axios.put("/laboratory/" + this.id, this.form);
        this.showSnackbar({
          text: "Parfait !\nLe laboratoire a été modifié avec succes!",
          multiline: true,
          type: "success"
        });
        this.success();
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * emit success when laboratory created/ updated successfully
     */
    success () {
      this.$emit("success");
      this.clearForm();
      this.close();
    },
    /**
     * Clear form fields
     */
    clearForm () {
      this.form.acronym = "";
      this.form.director.lastName = "";
      this.form.director.firstName = "";
      this.form.director.email = "";
    },
    /**
     * emit close when laboratory is canceled
     */
    close () {
      this.$emit("close");
    },
    /**
     * Fetch the selected laboratory
     */
    async fetchLaboratory () {
      await this.$axios.get("/laboratory/" + this.id)
        .then((response) => {
          this.form = response.data;
          this.form._id = undefined;
        })
        .catch((err) => console.log(err));
    }
  }
};

</script>

<style>
.input-number input[type="number"] {
  -moz-appearance: textfield;
}

.input-number input::-webkit-outer-spin-button,
.input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<template>
  <v-form ref="form">
    <v-container class="py-0">
      <v-layout align-center>
        <v-flex xs6>
          <span class="primary--text title">I. Type de dossier • Application type:</span>
        </v-flex>
        <v-flex xs6>
          <v-radio-group
            v-model="form.type"
            v-validate="'required'"
            name="guestType"
            :error-messages="errors.collect('guestType')"
            row
          >
            <span id="PROFESSOR">
              <v-radio label="Professeur visiteur (Visiting Professor)" value="PROFESSOR"></v-radio>
            </span>
            <v-spacer></v-spacer>
            <span id="LECTURER">
              <v-radio label="Maître de conférences visiteur (Visiting Lecturer)" value="LECTURER"></v-radio>
            </span>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <p class="primary--text title">II. Renseignement sur l'invitant • Inviting information</p>
      <h3>Réferent USPN de l'invitation • invitation identity</h3>
      <v-layout row wrap>
        <v-flex xs4>
          <v-text-field
            v-model="form.host.lastName"
            label="Nom • Last name"
            name="lastName"
            id="hostLastName"
            v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
            :error-messages="errors.collect('lastName')"
            disabled
          ></v-text-field>
        </v-flex>

        <v-flex xs4>
          <v-text-field
            v-model="form.host.firstName"
            label="Prénom • First Name"
            v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
            name="firstName"
            id="hostFirstName"
            :error-messages="errors.collect('firstName')"
            disabled
          ></v-text-field>
        </v-flex>

        <v-flex xs4>
          <v-text-field
            v-model="form.host.email"
            label="E-mail"
            v-validate="'required|email'"
            name="email"
            id="hostEmail"
            :error-messages="errors.collect('email')"
            disabled
          ></v-text-field>
        </v-flex>

        <v-flex xs4 xs6>
          <v-text-field
            v-model="form.host.institution.laboratory"
            :items="laboratories"
            label="Laboratoire d'accueil • Host laboratory"
            v-validate="'required'"
            name="hostLaboratory"
            id="hostLaboratory"
            :error-messages="errors.collect('hostLaboratory')"
            disabled
          ></v-text-field>
        </v-flex>

        <v-flex xs6>
          <v-text-field
            v-model="form.host.institution.team"
            label="équipe de recherche • research team"
            v-validate="'required'"
            name="hostTeam"
            id="hostTeam"
            @keyup="form.host.institution.team = updateTeam(form.host.institution.team)"
            :error-messages="errors.collect('hostTeam')"
            disabled
          ></v-text-field>
        </v-flex>
      </v-layout>

      <p
        class="primary--text title"
      >III. Renseignements sur le projet de recherche • Research project informations</p>
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            v-model="form.project.title"
            label="Titre du projet • Project title"
            v-validate="'required'"
            name="hostTitle"
            id="hostTitle"
            :error-messages="errors.collect('hostTitle')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="form.project.summary"
            label="Résumé du projet de recherche • Summary of research project"
            v-validate="'required'"
            name="hostSummary"
            id="hostSummary"
            :error-messages="errors.collect('hostSummary')"
          ></v-textarea>
        </v-flex>
        <v-flex shrink>
          <v-btn
            color="primary"
            class="button-info ml-0"
            large
            dark
            @click.prevent="showUploadDocumentDialog()"
            type="file"
          >
            <v-subheader>{{ document.name }}</v-subheader>
            <v-icon right>cloud_upload</v-icon>
          </v-btn>
        </v-flex>
        <v-flex shrink v-if="document.file">
          <v-subheader class="ml-0">{{ document.file.name || document.fileName }}</v-subheader>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3 class="mr-5">
          <date-selector
            id="stayDatesStart"
            label="Date de début de séjour • Start date of stay"
            v-model="form.stayDate.start"
            v-validate="'required|date_format:yyyy-MM-dd|before:'+form.stayDate.end+'|after:'+campaignStayDateStart"
            @success="form.stayDate.end = setDate(form.stayDate.start, form.stayDate.end)"
            name="stayDatesStart"
            :error="errors.first('stayDatesStart')"
            :min="campaignStayDateStart"
            :max="campaignStayDateEnd"
          />
        </v-flex>
        <v-flex xs3 class="mr-5">
          <date-selector
            id="stayDatesEnd"
            label="Date de fin de séjour • End of stay"
            v-model="form.stayDate.end"
            v-validate="'required|date_format:yyyy-MM-dd|before:'+campaignStayDateEnd+'|after:'+form.stayDate.start"
            name="stayDatesEnd"
            :error="errors.first('stayDatesEnd')"
            :min="addDays(form.stayDate.start, dayMin)"
            :max="addDays(form.stayDate.start, dayMax)"
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            v-model="form.transportationCost"
            label="Estimation des frais de transports en euros • Estimation of the transportation costs in euros."
            hint="Attention : un seul A/R maximum par an entre Paris et le lieu de résidence pris en charge par le BRED
             • Attention: only one round trip per year between Paris and the place of residence supported by BRED"
            persistent-hint
            suffix="€"
            v-validate="'required|min_value:0'"
            name="hostTransportationCost"
            id="hostTransportationCost"
            :error-messages="errors.collect('hostTransportationCost')"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-divider class="my-2"></v-divider>
      <v-layout row>
        <v-spacer />
        <v-btn color="primary" @click.prevent="submit">Continuer</v-btn>
        <v-btn color="secondary" :id="'closeBtn'" @click="$emit('close')">Fermer</v-btn>
      </v-layout>

      <input id="document" v-show="false" ref="inputDocument" type="file" name="document" @change="documentSave" />
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateSelector from "@/components/utils/DateSelector.vue";

export default {
  name: "HostForm",
  components: {
    dateSelector
  },
  computed: {
    ...mapGetters([
      "user" // username of the logged user
    ])
  },
  props: {
    editApplicationId: {
      type: String
    },
    campaignId: {
      type: String
    }
  },
  data () {
    return {
      ApplicationId: "",
      form: {
        host: {
          institution: {
            laboratory: "",
            team: ""
          },
          identity: {
            firstName: "",
            lastName: "",
            email: ""
          }
        },
        stayDate: {
          start: this.today(),
          end: this.today()
        },
        project: {
          title: "",
          summary: ""
        },
        transportationCost: "",
        type: "",
        campaign: ""
      },
      campaignStayDateStart: "",
      campaignStayDateEnd: "",
      civ: [
        "F",
        "M"
      ],
      laboratories: [],
      dayMin: 0,
      dayMax: 0,
      document: {
        file: "",
        name: "Importer un document",
        fileName: ""
      }
    };
  },
  watch: {
    editApplicationId (newId) {
      if (!newId) return;
      this.ApplicationId = newId;
    },
    ApplicationId (newId) {
      if (!newId) return;
      this.fetchHostForm();
    },
    campaignId (newId) {
      if (!newId) return;
      this.fetchCampaign();
    }
  },
  mounted () {
    this.fetchLaboratories();
    if (this.campaignId) this.fetchCampaign();
    this.initForm();
    if (!this.editApplicationId) return;
    this.fetchHostForm();
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * fetch stay dates of the campaign
    */
    async fetchCampaign () {
      await this.$axios.get("/campaign/" + this.campaignId)
        .then((response) => {
          this.campaignStayDateStart = response.data.stay.dates.start.substr(0, 10);
          this.campaignStayDateEnd = response.data.stay.dates.end.substr(0, 10);
          this.form.stayDate.start = this.campaignStayDateStart;
          this.form.stayDate.end = this.campaignStayDateEnd;
          this.dayMin = response.data.stay.numberDays.minimum - 1;
          this.dayMax = response.data.stay.numberDays.maximum;
        })
        .catch((err) => console.log(err));
    },
    /**
      * Initialise all laboratories
      */
    fetchLaboratories () {
      return this.$axios.get("/laboratory")
        .then(response => {
          response.data.forEach(laboratory => {
            this.laboratories.push(laboratory.acronym);
          });
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },
    /**
     * Initialise Host form
     */
    initForm () {
      this.form.host.firstName = this.user.firstName;
      this.form.host.lastName = this.user.lastName;
      this.form.host.email = this.user.email;
      this.form.host.institution.laboratory = this.user.institution.laboratory.acronym;
      this.form.host.institution.team = this.user.institution.team;

      this.user.roles.forEach(role => {
        if (role.description === "laboratory") this.form.host.institution.laboratory = role.name;
      });
    },
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");
        this.$emit("hostForm", this.form.stayDate, this.form.project, this.form.transportationCost, this.form.type, this.form.campaign, this.document.file);
      } catch (error) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Fetch host form when edit application
     */
    async fetchHostForm () {
      try {
        const application = await this.$axios.get("/application/" + this.editApplicationId);
        const campaign = application.data.campaign;

        if (application.data.document) {
          const filePathArray = application.data.project.document.split("/");
          this.document.fileName = filePathArray[filePathArray.length - 1];
        }

        this.form.project = application.data.project;
        this.form.transportationCost = application.data.transportationCost;
        this.form.stayDate.start = application.data.stayDate.start.substr(0, 10);
        this.form.stayDate.end = application.data.stayDate.end.substr(0, 10);
        this.form.type = application.data.type;
        this.form.campaign = campaign._id;

        this.campaignStayDateStart = campaign.stay.dates.start.substr(0, 10);
        this.campaignStayDateEnd = campaign.stay.dates.end.substr(0, 10);
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Clear form fields
     */
    clearForm () {
      this.form.stayDate.start = this.today();
      this.form.stayDate.end = this.today();
      this.form.project.title = "";
      this.form.project.summary = "";
      this.form.transportationCost = "";
      this.form.type = "";
      this.ApplicationId = "";
    },
    /**
     * Set stayDate.end if stayDate.start > stayDate.end
     * @param {string} before - stayDate.start
     * @param {string} after - stayDate.end
     */
    setDate (before, after) {
      let beforePlus30 = new Date(before);
      beforePlus30.setSeconds(86400 * 30);
      beforePlus30 = new Date(beforePlus30).toISOString().substr(0, 10);
      if (before > after) return before;
      else if (beforePlus30 < after) return beforePlus30;
      else return after;
    },
    addDays (date, days) {
      const mewDate = new Date(date);
      mewDate.setSeconds(86400 * days);
      return new Date(mewDate).toISOString().substr(0, 10);
    },
    updateTeam (team) {
      return team.toUpperCase().trim();
    },
    /**
     * Shows the dialog for uploading a document
     */
    showUploadDocumentDialog () {
      this.$refs.inputDocument.click();
    },
    /**
     * Save the selected document
     * @param {File} document - the document
     */
    documentSave (document) {
      if (document.target.files[0]) this.document.file = document.target.files[0];
      this.document.name = "Le document a été importé";
    }
  }
};

</script>

<template>
  <v-container>
    <v-layout row wrap v-if="campaign && application">
      <v-flex>
        <v-card dark color="secondary" width="100%" class="mb-3">
          <v-card-text class="warning-message">
            Veuillez prendre connaissance de la note d'information avant de
            déposer vos séjours
            <v-icon class="pl-1 pr-2">warning</v-icon>Please take notice of the
            information note before submitting any information
          </v-card-text>
        </v-card>
      </v-flex>

      <!-- campaign information -->
      <v-flex xs12 shrink class="mb-2">
        <v-card>
          <v-card-text class="pb-2">
            <v-layout row wrap>
              <!-- number of stays allowed by the campaign -->
              <v-flex xs4>
                <span class="font-weight-bold"
                  >Nombre de séjours autorisés :</span
                >
                {{ campaign.stay.numberTravels }}
              </v-flex>

              <!-- Number of days alloweb by the CR -->
              <v-flex xs4>
                <span class="font-weight-bold"
                  >Nombre de jours votés par la CR :</span
                >
                {{ application.vote.research.days }}
              </v-flex>

              <!-- transport costs -->
              <v-flex xs4>
                <span class="font-weight-bold"
                  >Frais de transport voté par la CR :</span
                >
                {{ application.vote.research.transportationCost }} €
              </v-flex>
            </v-layout>

            <v-divider class="my-2" />
            <v-layout row justify-center>
              <!-- Remaining days -->
              <v-flex xs3>
                <span class="font-weight-bold"
                  >Nombre de jours restants :&nbsp;</span
                >
                <span
                  :class="[
                    remainingDays < 0 ? 'red--text font-weight-bold' : ''
                  ]"
                  >{{ remainingDays }}</span
                >
              </v-flex>

              <!-- Remaining stays -->
              <v-flex xs3>
                <span class="font-weight-bold"
                  >Nombre de séjours restants :</span
                >
                {{ remainingStays }}
              </v-flex>
            </v-layout>
            <v-divider class="my-2" />
            <!-- bank information -->
            <v-layout row wrap justify-center>
              <v-flex shrink>
                <v-btn
                  color="info"
                  class="button-info"
                  outline
                  small
                  @click.prevent="downloadCampaignForm('informationNote')"
                >
                  Note d'Information
                  <v-icon right>get_app</v-icon>
                </v-btn>
              </v-flex>

              <v-flex shrink>
                <v-btn
                  color="info"
                  class="button-info"
                  outline
                  small
                  @click.prevent="downloadCampaignForm('paymentForm', 'docx')"
                >
                  Formulaire de Paiement
                  <v-icon right>get_app</v-icon>
                </v-btn>
              </v-flex>

              <v-flex shrink>
                <v-btn
                  color="info"
                  class="button-info"
                  outline
                  small
                  @click.prevent="
                    downloadCampaignForm('agentRegistrationForm', 'docx')
                  "
                >
                  Formulaire de Création d'Agent
                  <v-icon right>get_app</v-icon>
                </v-btn>
              </v-flex>

              <v-flex shrink>
                <!-- button to load bank information -->
                <v-btn
                  color="error"
                  @click.prevent="$refs.inputRIB.click()"
                  outline
                  small
                >
                  Informations bancaires
                  <v-icon right>cloud_upload</v-icon>
                </v-btn>
                <span class="font-italic">{{ bankInformationMessage }}</span>
              </v-flex>

              <input
                id="rib"
                v-show="false"
                ref="inputRIB"
                type="file"
                name="rib"
                accept=".pdf"
                @change="RIBSave"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!-- table listing the stays -->
      <v-flex xs12 shrink class="mb-2">
        <v-sheet style="position: relative" elevation="0">
          <v-data-table
            :headers="headers"
            :items="form.stays"
            item-key="name"
            class="elevation-1"
          >
            >
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  align="center"
                  :class="['column']"
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr>
                <td class="text-xs-center">
                  <v-btn
                    v-if="!props.item.status"
                    color="primary"
                    flat
                    icon
                    @click.prevent="validateStay(props.index)"
                  >
                    <v-icon medium>done</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    flat
                    icon
                    @click.prevent="editStay(props.index)"
                  >
                    <v-icon medium>edit</v-icon>
                  </v-btn>
                  <v-btn
                    color="secondary"
                    flat
                    icon
                    @click.prevent="deleteStay(props.index)"
                  >
                    <v-icon medium>delete</v-icon>
                  </v-btn>
                </td>

                <td class="text-xs-center px-24">
                  <date-selector
                    :disabled="props.item.status.length !== 0"
                    :id="'stayDatesStart' + props.index"
                    label="Date de début de séjour • Start date of stay"
                    v-model="props.item.start"
                    v-validate="
                      'required|date_format:yyyy-MM-dd|before:' +
                        props.item.end +
                        '|after:' +
                        campaign.stay.dates.start
                    "
                    name="stayDatesStart"
                    :error="errors.first('stayDatesStart')"
                    :min="campaign.stay.dates.start"
                    :max="campaign.stay.dates.end"
                  />
                </td>

                <td class="text-xs-center">
                  <date-selector
                    :disabled="props.item.status.length !== 0"
                    :id="'stayDatesEnd' + props.index"
                    label="Date de fin de séjour • End of stay"
                    v-model="props.item.end"
                    v-validate="
                      'required|date_format:yyyy-MM-dd|before:' +
                        campaign.stay.dates.end +
                        '|after:' +
                        props.item.start
                    "
                    name="stayDatesEnd"
                    :error="errors.first('stayDatesEnd')"
                    :min="props.item.start"
                    :max="campaign.stay.dates.end"
                  />
                </td>

                <!-- Certificate Service -->
                <td class="text-xs-center">
                  <template v-if="props.item.status === 'validated'">
                    <!-- Generate Certificate -->
                    <v-btn
                      :disabled="
                        props.item.certificateServiceDone.status === 'filed'
                      "
                      :color="documentStatus(props.item.certificateServiceDone)"
                      class="button-info"
                      fab
                      small
                      outline
                      @click.prevent="
                        generateDocument(
                          application,
                          props.item,
                          'certificateServiceDone'
                        )
                      "
                    >
                      <v-icon>autorenew</v-icon>
                    </v-btn>

                    <!-- Upload signed certificate -->
                    <v-btn
                      :disabled="
                        props.item.certificateServiceDone.status === 'untreated'
                      "
                      :color="documentStatus(props.item.certificateServiceDone)"
                      class="button-info"
                      fab
                      small
                      outline
                      @click.prevent="
                        showUploadDocumentDialog(
                          application._id,
                          props.item.key,
                          'certificateServiceDone'
                        )
                      "
                    >
                      <v-icon>cloud_upload</v-icon>
                    </v-btn>

                    <!-- Download certificate -->
                    <v-btn
                      :disabled="
                        props.item.certificateServiceDone.status === 'untreated'
                      "
                      :color="documentStatus(props.item.certificateServiceDone)"
                      class="button-info"
                      fab
                      small
                      outline
                      @click.prevent="
                        downloadDocument(
                          application._id,
                          props.item.key,
                          'certificateServiceDone'
                        )
                      "
                    >
                      <v-icon>get_app</v-icon>
                    </v-btn>
                  </template>
                </td>
              </tr>
            </template>
            <v-alert slot="no-data" :value="true" color="light-blue" icon="info"
              >Il semblerait qu'il n'y ait pas encore de séjour.</v-alert
            >
          </v-data-table>

          <!-- Button to add new stays -->
          <v-btn
            v-if="remainingStays > 0"
            color="primary"
            dark
            fab
            @click.prevent="addStay()"
            small
            absolute
            bottom
            style="right: 50%; bottom: 10px"
          >
            <v-icon medium>add</v-icon>
          </v-btn>
          <!-- </div> -->
        </v-sheet>
      </v-flex>

      <!-- save button -->
      <v-flex xs12 shrink>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              class="white--text"
              :disabled="!staysValidated"
              @click="submit"
              >Sauvegarder</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Dialog to upload file -->
    <input
      id="document"
      v-show="false"
      ref="inputDocument"
      type="file"
      accept=".pdf"
      :name="documentDialogInfo.document"
      @change="documentSave"
    />
  </v-container>
</template>

<script>
import dateSelector from "@/components/utils/DateSelector.vue";
import { mapGetters, mapActions } from "vuex";
import { saveAs } from "file-saver";
const moment = require("moment");

export default {
  props: {
    applicationId: {
      type: String
    }
  },
  components: {
    dateSelector
  },
  data: () => ({
    headers: [
      { text: "Actions", value: "key" },
      { text: "Date de début de séjour", value: "start" },
      { text: "Date de fin de séjour", value: "end" },
      { text: "Attestation de service fait", value: "key" }
    ],
    form: {
      application: "",
      bankInformation: "",
      stays: []
    },
    application: null, // application full data
    campaign: null, // campaign full data
    documentDialogInfo: {}
  }),
  async mounted () {
    if (this.applicationId) await this.refreshData();
  },
  watch: {
    async applicationId (newId) {
      if (newId) await this.refreshData();
    }
  },
  computed: {
    ...mapGetters(["snackbarVisible", "user"]),
    remainingDays () {
      const days = this.form.stays.reduce(
        (acc, stay) => acc + moment(stay.end).diff(moment(stay.start), "days"),
        1
      );
      return this.application ? this.application.vote.research.days - days : 0;
    },
    remainingStays () {
      return this.campaign.stay.numberTravels - this.form.stays.length;
    },
    staysValidated () {
      const result = this.form.stays.find(stay => stay.status === "");
      return result === undefined;
    },
    bankInformationMessage () {
      const info = this.form.bankInformation;
      if (!info) return "Veuillez ajouter les informations bancaires";
      return info instanceof File ? info.name : "";
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    fetchApplication () {
      return this.$axios
        .get("/application/" + this.applicationId)
        .then(response => {
          this.application = response.data;
          this.application.stayDate.start = new Date(
            this.application.stayDate.start
          )
            .toISOString()
            .substr(0, 10);
          this.application.stayDate.end = new Date(
            this.application.stayDate.end
          )
            .toISOString()
            .substr(0, 10);

          // campaign info of the application
          this.campaign = this.application.campaign;
          this.campaign.stay.dates.start = new Date(
            this.campaign.stay.dates.start
          )
            .toISOString()
            .substr(0, 10);
          this.campaign.stay.dates.end = new Date(this.campaign.stay.dates.end)
            .toISOString()
            .substr(0, 10);
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },

    async fetchApplicationManagementInfo () {
      try {
        let { data: form } = await this.$axios.get(
          "/applicationManagement/application/" + this.applicationId
        );
        form.stays = form.stays.map(stay => {
          stay.status = "validated";
          stay.start = moment(stay.start).format(moment.HTML5_FMT.DATE);
          stay.end = moment(stay.end).format(moment.HTML5_FMT.DATE);
          return stay;
        });

        this.form = form;
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },

    async refreshData () {
      await Promise.all([
        this.fetchApplication(),
        this.fetchApplicationManagementInfo()
      ]);
    },

    /**
     * Add selected RIB file to the form
     *
     * @param {File} rib - the RIB
     */
    RIBSave (rib) {
      if (rib.target.files[0]) this.form.bankInformation = rib.target.files[0];
    },

    addStay () {
      const startDate = this.form.stays.length
        ? this.form.stays[this.form.stays.length - 1].end
        : moment()
          .add(1, "days")
          .format(moment.HTML5_FMT.DATE);

      const endDate = moment(startDate)
        .add(this.campaign.stay.numberDays.minimum, "days")
        .format(moment.HTML5_FMT.DATE);

      this.form.stays.push({
        key: this.form.stays.length, // TODO: autoincrement in model
        start: startDate,
        end: endDate,
        status: "", // it does not belong to the model
        pvi: {
          file: "",
          status: "untreated"
        },
        invitationLetter: {
          fr: {
            file: "",
            status: "untreated"
          },
          en: {
            file: "",
            status: "untreated"
          }
        },
        certificateServiceDone: {
          file: "",
          status: "untreated"
        }
      });
    },

    validateStay (index) {
      if (this.remainingDays >= 0) {
        this.form.stays[index].status = "validated";
      } else {
        this.showSnackbar({
          text: "Oups ! Vous avez dépassé le nombre de jours votés par la CR",
          type: "error",
          multiline: true
        });
      }
    },

    editStay (index) {
      this.form.stays[index].status = "";
    },

    deleteStay (index) {
      this.form.stays.splice(index, 1);
    },

    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Vérifiez vos séjours");

        const stays = this.form.stays.map(stay => {
          const freezeStay = { ...stay, status: undefined };
          return freezeStay;
        });
        const validatedForm = { ...this.form, stays };

        this.$emit("submitForm", validatedForm);
      } catch (error) {
        this.showSnackbar({
          text: `Oups ! ${error.message}\n`,
          type: "error",
          multiline: true
        });
      }
    },

    documentStatus (doc) {
      if (doc.status === "untreated") return "red";
      else if (doc.status === "generated") return "orange";
      else return "green";
    },

    async generateDocument (application, stay, document) {
      try {
        const days = moment(stay.end).diff(moment(stay.start), "days") + 1;

        let totalAmount = this.campaign.stay.amountPerDay * days;
        totalAmount = Math.round(100 * totalAmount) / 100;

        const template = document.replace(/\.(en|fr)$/i, w =>
          w.replace(".", "").toUpperCase()
        );

        const form = {
          stay,
          days,
          totalAmount,
          document,
          application,
          campaign: this.campaign
        };

        const response = await this.$axios.post(
          `/applicationManagement/generate/${template}`,
          form,
          { responseType: "arraybuffer" }
        );
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Upload generated file
        this.documentDialogInfo = {
          application: application._id,
          stay: stay.key,
          status: "generated",
          document
        };
        const file = new File([blob], `${template}.pdf`, {
          lastModified: new Date()
        });
        await this.documentSave({ target: { files: [file] } });

        saveAs(blob, `${template}.pdf`);
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multline: true
        });
      }
    },

    documentSave (documentFile) {
      if (documentFile.target.files[0]) {
        const formData = new FormData();
        const { document, application, stay, status } = this.documentDialogInfo;
        formData.append(document, documentFile.target.files[0]);

        const url = `/applicationManagement/upload/${document}/${application}/${stay}/${status}`;
        return this.$axios
          .put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
          })
          .then(() => {
            this.showSnackbar({
              text: "Parfait !\nVotre document a bien été ajouté.",
              type: "success",
              multiline: true
            });
          })
          .then(() => this.refreshData())
          .catch(() => {
            this.showSnackbar({
              text: "Oups !\nErreur: ",
              type: "error",
              multiline: true
            });
          });
      }

      this.documentDialogInfo = {};
    },

    showUploadDocumentDialog (application, stay, document) {
      this.documentDialogInfo = {
        application,
        stay,
        document,
        status: "filed"
      };
      this.$refs.inputDocument.click();
    },

    downloadDocument (application, key, document) {
      return this.$axios
        .get(
          `/applicationManagement/download/${document}/${application}/${key}`,
          { responseType: "blob" }
        )
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const filename = document.replace(/\.(en|fr)$/i, w =>
            w.replace(".", "").toUpperCase()
          );
          saveAs(blob, `${filename}.pdf`);
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups !\nErreur",
            type: "error",
            multiline: true
          });
        });
    },

    async downloadCampaignForm (doc, format = "pdf") {
      try {
        const response = await this.$axios.get(
          `/campaign/download/${this.campaign._id}/${doc}`,
          { responseType: "blob" }
        );
        const blob = new Blob([response.data], {
          type: `application/${format}`
        });
        saveAs(blob, `${doc}.${format}`);
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nErreur",
          type: "error",
          multiline: true
        });
      }
    }
  }
};
</script>

<template>
  <v-card>
    <v-card-title>
      <span class="headline">Création : Campagne de Professeurs Invités</span>
    </v-card-title>

    <!-- Form with information of the new campaign -->
    <v-card-text>
      <v-form ref="form">
        <v-container grid-list-md>
          <v-layout row>
            <!-- The amount per day for the stay -->
            <v-flex xs3>
              <v-text-field
                id="stayAmountPerDay"
                data-cy="stayAmountPerDay"
                label="Montant des indemnités journalières"
                v-model="form.stay.amountPerDay"
                v-validate="'required|min_value:0'"
                name="stayAmountPerDay"
                :error-messages="errors.collect('stayAmountPerDay')"
                type="number"
                class="input-number"
                placeholder="0"
                suffix="€"
                required
              />
            </v-flex>

            <!-- The number of day for the stay -->
            <!-- Minimum -->
            <v-flex xs3>
              <v-text-field
                id="stayNumberDaysMinimum"
                data-cy="stayNumberDaysMinimum"
                label="Nombre de jours minimum"
                v-model="form.stay.numberDays.minimum"
                v-validate="'required|min_value:0|max_value:'+form.stay.numberDays.maximum"
                name="stayNumberDaysMinimum"
                :error-messages="errors.collect('stayNumberDaysMinimum')"
                type="number"
                class="input-number"
                placeholder="0"
                required
              />
            </v-flex>

            <!-- Maximum -->
            <v-flex xs3>
              <v-text-field
                id="stayNumberDaysMaximum"
                data-cy="stayNumberDaysMaximum"
                label="Nombre de jours maximum"
                v-model="form.stay.numberDays.maximum"
                v-validate="'required|min_value:'+form.stay.numberDays.minimum"
                name="stayNumberDaysMaximum"
                :error-messages="errors.collect('stayNumberDaysMaximum')"
                type="number"
                placeholder="0"
                class="input-number"
                required
              />
            </v-flex>

            <!-- Number of travels -->
            <v-flex xs3>
              <v-text-field
                id="stayNumberTravels"
                data-cy="stayNumberTravels"
                label="Nombre de voyages autorisés"
                v-model="form.stay.numberTravels"
                v-validate="'required|min_value:1'"
                name="stayNumberTravels"
                :error-messages="errors.collect('stayNumberTravels')"
                type="number"
                placeholder="0"
                class="input-number"
                required
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <!-- The current president -->
            <v-flex xs5>
              <v-text-field
                id="president"
                data-cy="president"
                label="Président actuel "
                v-model="form.president"
                v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                name="president"
                :error-messages="errors.collect('president')"
                placeholder="John Doe"
                required
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                id="manager"
                data-cy="manager"
                label="Responsable BRED"
                v-model="form.manager"
                v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                name="manager"
                :error-messages="errors.collect('manager')"
                placeholder="John Doe"
                required
              />
            </v-flex>
            <!-- Budget maximum -->
            <v-flex xs2>
              <v-text-field
                id="maxBudget"
                data-cy="maxBudget"
                label="Budget maximum"
                v-model="form.maxBudget"
                v-validate="'required|min_value:0'"
                name="maxBudget"
                :error-messages="errors.collect('maxBudget')"
                type="number"
                placeholder="0"
                suffix="€"
                class="input-number"
                required
              />
            </v-flex>
          </v-layout>
          <v-layout justify-space-around row>
            <!-- Call document publication date -->
            <v-flex xs4 pr-3>
              <date-selector
                id="datesCampaignPublication"
                dataCy="datesCampaignPublication"
                name="datesCampaignPublication"
                label="Publication de l'appel d'offre"
                v-model="form.datesCampaign.publication"
                v-validate="'required|date_format:yyyy-MM-dd|before:'+form.datesCampaign.opening+', inclusion:true'+'|after:'+date+', inclusion:true'"
                @success="form.datesCampaign.opening = setDate(form.datesCampaign.publication, form.datesCampaign.opening)"
                :error="errors.first('datesCampaignPublication')"
                :min="date"
              />
            </v-flex>

            <!-- Campaign opening date -->
            <v-flex xs4 pr-3 pl-3>
              <date-selector
                id="datesCampaignOpening"
                dataCy="datesCampaignOpening"
                name="datesCampaignOpening"
                label="Ouverture de la campagne"
                v-model="form.datesCampaign.opening"
                v-validate="'required|date_format:yyyy-MM-dd|before:'+form.datesCampaign.deadline+'|after:'+form.datesCampaign.publication+', inclusion:true'"
                @success="form.datesCampaign.deadline = deadline()"
                :error="errors.first('datesCampaignOpening')"
                :min="form.datesCampaign.publication"
                :max="(parseInt(form.datesCampaign.publication.substr(0,4)) + 2).toString()"
              />
            </v-flex>

            <!-- Deadline for filing application files -->
            <v-flex xs4 pl-3>
              <date-selector
                id="datesCampaignDeadline"
                dataCy="datesCampaignDeadline"
                name="datesCampaignDeadline"
                label="Date limite de dépôt des dossiers"
                v-model="form.datesCampaign.deadline"
                v-validate="'required|date_format:yyyy-MM-dd|after:'+form.datesCampaign.opening"
                @success="form.datesCommittee.adHoc = setDate(form.datesCampaign.deadline, form.datesCommittee.adHoc)"
                :error="errors.first('datesCampaignDeadline')"
                :min="campaignDeadline"
                :max="(parseInt(form.datesCampaign.publication.substr(0,4)) + 2).toString()"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <!-- Ad Hoc commission date -->
            <v-flex xs6 pr-3>
              <date-selector
                id="datesCommitteeAdHoc"
                dataCy="datesCommitteeAdHoc"
                name="datesCommitteeAdHoc"
                label="Date de la commission Ad Hoc"
                v-model="form.datesCommittee.adHoc"
                v-validate="'required|date_format:yyyy-MM-dd|before:'+form.datesCommittee.research+'|after:'+form.datesCampaign.deadline+', inclusion:true'"
                @success="form.datesCommittee.research = setDate(form.datesCommittee.adHoc, form.datesCommittee.research)"
                :error="errors.first('datesCommitteeAdHoc')"
                :min="form.datesCampaign.deadline"
                :max="(parseInt(form.datesCampaign.publication.substr(0,4)) + 2).toString()"
              />
            </v-flex>

            <!-- Research commission date -->
            <v-flex xs6 pl-3>
              <date-selector
                id="datesCommitteeResearch"
                dataCy="datesCommitteeResearch"
                name="datesCommitteeResearch"
                label="Date de validation par la CR"
                v-model="form.datesCommittee.research"
                v-validate="'required|date_format:yyyy-MM-dd|after:'+form.datesCommittee.adHoc"
                @success="form.stay.dates.start = setDate(form.datesCommittee.research, form.stay.dates.start)"
                :error="errors.first('datesCommitteeResearch')"
                :min="form.datesCommittee.adHoc"
                :max="(parseInt(form.datesCampaign.publication.substr(0,4)) + 2).toString()"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <!-- Deadlines for stays -->
            <v-flex xs6 pr-3>
              <!-- Start date -->
              <date-selector
                id="stayDatesStart"
                dataCy="stayDatesStart"
                name="stayDatesStart"
                label="Date de début de séjour"
                v-model="form.stay.dates.start"
                v-validate="'required|date_format:yyyy-MM-dd|before:'+form.stay.dates.end+'|after:'+form.datesCommittee.research"
                @success="form.stay.dates.end = setDate(form.stay.dates.start, form.stay.dates.end)"
                :error="errors.first('stayDatesStart')"
                :min="form.datesCommittee.research"
                :max="(parseInt(form.datesCampaign.publication.substr(0,4)) + 2).toString()"
              />
            </v-flex>

            <!-- End date -->
            <v-flex xs6 pl-3>
              <date-selector
                id="stayDatesEnd"
                dataCy="stayDatesEnd"
                name="stayDatesEnd"
                label="Date de fin de séjour"
                v-model="form.stay.dates.end"
                v-validate="'required|date_format:yyyy-MM-dd|after:'+form.stay.dates.start"
                :error="errors.first('stayDatesEnd')"
                :min="form.stay.dates.start"
                :max="(parseInt(form.datesCampaign.publication.substr(0,4)) + 2).toString()"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider class="mt-1"></v-divider>
    <v-card-actions>
      <v-spacer />
      <!-- close button -->
      <v-btn color="secondary" id="closeBtn" data-cy="closeBtn" @click="close">Fermer</v-btn>
      <!-- Submit button -->
      <v-btn
        v-if="!id"
        id="submitCreate"
        data-cy="submitCreate"
        :disabled="snackbarVisible"
        color="primary"
        @click.prevent="submit"
      >Ajouter</v-btn>
      <v-btn
        v-else
        id="submitUpdate"
        data-cy="submitUpdate"
        :disabled="snackbarVisible"
        color="primary"
        @click.prevent="update"
      >Modifier</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import campaignInformation from "@/data/Campaign.json";
import dateSelector from "@/components/utils/DateSelector.vue";

export default {
  components: {
    dateSelector
  },
  props: {
    id: {
      type: String
    }
  },
  data () {
    return {
      date: this.today(),
      campaignDeadline: this.today(),
      form: {
        stay: {
          amountPerDay: campaignInformation.amountPerDay,
          numberTravels: campaignInformation.numberTravels,
          numberDays: {
            minimum: campaignInformation.numberDaysMin,
            maximum: campaignInformation.numberDaysMax
          },
          dates: {
            start: this.today(),
            end: this.today()
          }
        },
        datesCampaign: {
          opening: this.today(),
          publication: this.today(),
          deadline: this.today()
        },
        datesCommittee: {
          adHoc: this.today(),
          research: this.today()
        },
        president: campaignInformation.president,
        manager: campaignInformation.manager,
        status: "created",
        maxBudget: 0
      }
    };
  },
  computed: {
    ...mapGetters(["snackbarVisible"])
  },
  watch: {
    id (newId) {
      if (!newId) return;
      this.fetchCampaign();
    }
  },
  mounted () {
    if (!this.id) return;
    this.fetchCampaign();
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        await this.$axios.post("/campaign", this.form);
        this.showSnackbar({
          text: "Parfait !\nLa campagne à été créé avec succes!",
          multiline: true,
          type: "success"
        });
        this.success();
        this.clearForm();
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     *Update campaign
     */
    async update () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        await this.$axios.put("/campaign/" + this.id, this.form);
        this.showSnackbar({
          text: "Parfait !\nLa campagne à été modifié avec succes!",
          multiline: true,
          type: "success"
        });
        this.success();
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * emit success when campaign created/ updated successfully
     */
    success () {
      this.$emit("success");
      this.close();
    },
    /**
     * Clear form fields
     */
    clearForm () {
      this.form.stay.amountPerDay = campaignInformation.amountPerDay;
      this.form.stay.numberTravels = campaignInformation.numberTravels;
      this.form.stay.numberDays.minimum = campaignInformation.numberDaysMin;
      this.form.stay.numberDays.maximum = campaignInformation.numberDaysMax;
      this.form.stay.dates.start = this.today();
      this.form.stay.dates.end = this.today();
      this.form.datesCampaign.opening = this.today();
      this.form.datesCampaign.publication = this.today();
      this.form.datesCampaign.deadline = this.today();
      this.form.datesCommittee.adHoc = this.today();
      this.form.datesCommittee.research = this.today();
      this.form.president = campaignInformation.president;
      this.form.manager = campaignInformation.manager;
    },
    /**
     * emit close when campaign is canceled
     */
    close () {
      this.$emit("close");
    },
    /**
     * Fetch the selected campaign
     */
    async fetchCampaign () {
      await this.$axios.get("/campaign/" + this.id)
        .then((response) => {
          this.form = response.data;
          this.form._id = undefined;
          this.form.manager = response.data.manager || ""; // backward compatibility reasons
          this.date = response.data.datesCampaign.publication.substr(0, 10);
          this.form.datesCampaign.publication = response.data.datesCampaign.publication.substr(0, 10);
          this.form.datesCampaign.opening = response.data.datesCampaign.opening.substr(0, 10);
          this.form.datesCampaign.deadline = response.data.datesCampaign.deadline.substr(0, 10);
          this.form.datesCommittee.adHoc = response.data.datesCommittee.adHoc.substr(0, 10);
          this.form.datesCommittee.research = response.data.datesCommittee.research.substr(0, 10);
          this.form.stay.dates.end = response.data.stay.dates.end.substr(0, 10);
          this.form.stay.dates.start = response.data.stay.dates.start.substr(0, 10);
        })
        .catch(err => console.log(err));
    },
    /**
     * Update the next date
     */
    setDate (before, after) {
      if (before > after) return before;
      else return after;
    },
    deadline () {
      this.form.datesCampaign.deadline = new Date(this.form.datesCampaign.opening);
      this.form.datesCampaign.deadline.setSeconds(86400 * 30);
      this.campaignDeadline = new Date(this.form.datesCampaign.deadline).toISOString().substr(0, 10);
      this.form.datesCommittee.adHoc = this.campaignDeadline;
      return this.campaignDeadline;
    }
  }
};
</script>

<style>
.input-number input[type="number"] {
  -moz-appearance: textfield;
}

.input-number input::-webkit-outer-spin-button,
.input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

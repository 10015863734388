<template>
  <v-dialog :value="value" v-if="form" persistent max-width="600px">
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout row wrap>
              <!-- Username -->
              <v-flex xs6>
                <v-text-field v-model="form.username" label="Identifiant" disabled />
              </v-flex>
              <!-- email -->
              <v-flex xs6>
                <v-text-field
                  v-model="form.email"
                  label="Courriel"
                  v-validate="'required|email'"
                  name="email"
                  :error-messages="errors.collect('email')"
                  required
                ></v-text-field>
              </v-flex>
              <!-- lastname -->
              <v-flex xs6>
                <v-text-field
                  v-model="form.lastName"
                  label="Nom"
                  v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                  name="lastName"
                  :error-messages="errors.collect('lastName')"
                  required
                />
              </v-flex>
              <!-- firstname -->
              <v-flex xs6>
                <v-text-field
                  v-model="form.firstName"
                  label="Prénom"
                  v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                  name="firstName"
                  :error-messages="errors.collect('firstName')"
                  required
                />
              </v-flex>

              <!-- laboratory -->
              <v-flex xs6>
                <v-autocomplete
                  v-model="form.institution.laboratory"
                  :items="laboratories"
                  label="Laboratoire d'accueil"
                  v-validate="'required'"
                  name="laboratory"
                  :error-messages="errors.collect('laboratory')"
                ></v-autocomplete>
              </v-flex>

              <!-- team -->
              <v-flex xs6>
                <v-text-field
                  :value="form.institution.team"
                  label="Équipe de recherche"
                  name="researchTeam"
                  @input="form.institution.team = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>
              <!-- roles -->
              <v-flex xs12>
                <v-select
                  v-model="form.roles"
                  :items="roles"
                  label="Rôle"
                  name="role"
                  :menu-props="menuProps"
                  @focus="adjustOptions"
                  @input="adjustOptions"
                  chips
                  deletable-chips
                  multiple
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider class="mt-1"></v-divider>
      <v-card-actions>
        <v-spacer />
        <!-- close button -->
        <v-btn color="secondary" @click="close">Fermer</v-btn>
        <v-btn color="primary" @click.prevent="update">Modifier</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["value", "user"],
  data: () => ({
    form: null,
    roles: [],
    laboratories: [],
    menuProps: {
      disabled: false
    }
  }),
  async mounted () {
    await Promise.all([this.fetchRoles(), this.fetchLaboratories()]);
  },
  watch: {
    /**
     * Freeze the user's information to be displayed on the widget
     */
    async value (newValue) {
      if (!newValue) {
        this.form = null;
        this.resetValidators();
      } else {
        await this.fetchUser(this.user);
      }
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Fetch the information of the selected user
     */
    async fetchUser (userId) {
      try {
        const { data } = await this.$axios.get("/user/" + userId);
        this.form = data;
        delete this.form._id;
      } catch (err) {
        console.log("error in UserForm component, fetchUser");
      }
    },
    /** Fetch all the laboratories */
    async fetchLaboratories () {
      try {
        const laboratories = await this.$axios.get("laboratory");
        this.laboratories = laboratories.data.map(l => ({ text: l.acronym, value: l._id }));
      } catch (err) {
        console.log("error in UserForm component, fetchLaboratories");
      }
    },
    /** Fetch all the roles */
    async fetchRoles () {
      try {
        const roles = await this.$axios.get("role");
        this.roles = roles.data.map(l => ({ text: l.name, value: l._id }));
      } catch (err) {
        console.log("error in UserForm component, fetchLaboratories");
      }
    },
    /** Close the form */
    close () {
      this.$emit("input", false);
    },
    /** Update user */
    async update () {
      try {
        // wait for confirmation
        const confirmation = await this.$root.$confirm(
          "Modification d'un utilisateur",
          "Êtes-vous sur de vouloir modifier cet utilisateur ?", // message
          { color: "primary" } // color
        );
        if (!confirmation) return;

        // check form fields
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        // call API
        await this.$axios.put("/user/" + this.user, this.form);
        this.showSnackbar({
          text: "Parfait !\n L'utilisateur a été modifié avec succes!",
          multiline: true,
          type: "success"
        });

        this.$emit("success", true);
        this.close();
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    /**
   * Function that fix the veeValidate bug when using v-if
   *
   * @see https://github.com/logaretm/vee-validate/issues/2109
   */
    resetValidators () {
      this.$validator.pause();
      this.$nextTick(() => {
        this.$validator.errors.clear();
        this.$validator.fields.items.forEach(field => field.reset());
        this.$validator.fields.items.forEach(field => this.errors.remove(field));
        this.$validator.resume();
      });
    },
    /** Avoid to select multiple roles at the same time */
    adjustOptions () {
      this.menuProps.disabled = this.form.roles.length >= 1;
    }
  }
};
</script>


const moment = require("moment");

export default {
  filters: {
    /**
     * Return a date using the french format
     * @param {String} stringDate - string of a date
     * @returns {String} date
     */
    getDate (stringDate) {
      if (!stringDate) return "";
      const date = stringDate.substring(0, 10);
      const frenchFormat = moment(date).locale("fr").format("L");
      return frenchFormat;
    }
  },
  methods: {
    /**
     * Returns the current year
     *
     * @returns String
     */
    currentYear () {
      return new Date().getFullYear();
    },
    /**
     * Returns the date of today
     *
     * @returns String
     */
    today () {
      const date = new Date().toISOString();
      return date.split("T")[0];
    }
  }
};

<template>
  <v-responsive height="100%">
    <v-container fill-height>
      <v-layout align-center fill-height>
        <v-flex md-6>
          <h3 class="display-3">Welcome to {{ title }}</h3>
          <h3 class="display-3">Bienvenue sur {{ title }}</h3>
          <span class="subheading"></span>
        </v-flex>

        <v-flex md-6 class="hidden-md-and-down">
          <v-card dark elevation="10">
            <v-playback :url="urlVideo"></v-playback>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-responsive>
</template>

<script>
import { mapGetters } from "vuex";
const videosPath = `${process.env.BASE_URL}videos`;

/**
 * Component showing a welcome message
 */
export default {
  name: "Welcome",
  data () {
    return {
      urlVideo: `${videosPath}/submitApplication.mp4`
    };
  },
  computed: {
    ...mapGetters(["title"])
  }
};
</script>

<style scoped lang="scss">
.jumbotron {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>

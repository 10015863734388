import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios";
import mixin from "./mixin";
import Vuetify from "vuetify";
import vPlayBack from "v-playback";
import VeeValidate from "../config/vee-validate";
import ConfirmDialog from "@lipn/confirm-dialog";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";

// Vue
Vue.config.productionTip = false;

// axios
Vue.prototype.$axios = axios;

// Vuetify
Vue.use(Vuetify, {
  theme: {
    primary: "#2e7d32",
    secondary: "#DB4437",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107"
  }
});

// Plugins
Vue.use(VeeValidate, { locale: "fr" });
Vue.use(vPlayBack);
Vue.use(ConfirmDialog);

// mixin
Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <v-container grid-list-md>
    <v-layout row>
      <v-flex xs4>
        <v-text-field v-model="fieldName" :label="head" :id="head+'TextField'"></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-btn
          :id="head+'Submit'"
          :disabled="!fieldName.trim()"
          @click.prevent="submit"
          fab
          small
          color="primary"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs8>
        <span v-for="element in array" :key="element.name">
          <v-chip
            :id="element.name"
            color="primary"
            class="white--text"
            close
            @input="deleteElement(element.name)"
            v-if="element.name"
          >{{element.name}}</v-chip>
        </span>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    head: {
      type: String,
      required: true
    },
    array: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      fieldName: ""
    };
  },
  methods: {
    submit () {
      this.$emit("add", this.fieldName);
      this.fieldName = "";
    },
    deleteElement (name) {
      this.$emit("delete", name);
    }
  }
};
</script>

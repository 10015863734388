<template>
  <v-container fluid>
    <v-layout row wrap class="mb-5">
      <v-flex xs12 class="mb-2">
        <h1 class="display-2">Dossiers</h1>
        <v-select
          outline
          v-model="currentCampaign"
          :items="campaigns"
          :item-text="campaignLabel"
          return-object
          label="Campaign"
          class="selectLeft mt-4"
          @change="fetchApplications"
        ></v-select>
      </v-flex>
      <v-flex xs12>
        <template v-if="isBRED || isAdmin">
          <v-btn color="secondary" class="ml-0 mt-0" dark @click="getProjectsCSV">
            CSV
            <v-icon right dark>save_alt</v-icon>
          </v-btn>
        </template>
        <v-btn color="secondary" class="ml-0 mt-0" dark @click="getProjects">
          Projets
          <v-icon right dark>save_alt</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card v-for="[lab, applicationsLab] in Object.entries(applications)" :key="lab" class="mb-3">
      <v-card-title>
        <span>
          <h1 class="primary--text">
            {{ lab }}
            <v-btn
              v-if="isDirector || isAdmin"
              color="secondary"
              class="ml-2 mt-0"
              dark
              @click="saveRanking(applicationsLab)"
            >
              Classement
              <v-icon right dark>save</v-icon>
            </v-btn>
          </h1>
        </span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search[lab]"
          append-icon="search"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
        <span>
          <v-subheader v-text="'Recherche rapide :'"></v-subheader>
        </span>
        <v-radio-group v-model="search[lab]" row>
          <v-radio label="Accepté" value="accepted"></v-radio>
          <v-radio label="Refusé" value="rejected"></v-radio>
          <v-radio label="En attente" value="waiting"></v-radio>
          <v-spacer></v-spacer>
          <v-radio label="LECTURER" value="lecturer"></v-radio>
          <v-radio label="PROFESSOR" value="professor"></v-radio>
        </v-radio-group>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="filteredHeaders"
        :items="applicationsLab"
        :search="search[lab]"
        :pagination.sync="pagination"
        select-all
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr :bgcolor="getStatus(props.item.status)">
            <td
              class="layout justify-center"
              v-if="isAdmin || isBRED || isDirector || isViewer"
            >
              <v-text-field
                v-model="props.item.rank"
                class="rankTextField"
                type="number"
                single-line
                box
                :disabled="!(isDirector || isAdmin) || !deadlineRanking"
              ></v-text-field>
            </td>
            <td class="text-xs-right">
              <span v-if="isBRED">
                <v-btn
                  class="btn"
                  v-if="props.item.status !== 'accepted'"
                  color="success"
                  fab
                  flat
                  dark
                  small
                  relative
                  :right="true"
                  @click="setApplication(props.item._id, 'accepted')"
                >
                  <v-icon>check</v-icon>
                </v-btn>
                <v-btn
                  class="btn"
                  v-if="props.item.status !== 'waiting'"
                  color="orange"
                  fab
                  flat
                  dark
                  small
                  relative
                  :right="true"
                  @click="setApplication(props.item._id, 'waiting')"
                >
                  <v-icon>access_time</v-icon>
                </v-btn>
                <v-btn
                  class="btn"
                  v-if="props.item.status !== 'rejected'"
                  color="error"
                  fab
                  flat
                  dark
                  small
                  relative
                  :right="true"
                  @click="setApplication(props.item._id, 'rejected')"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-btn
                  class="btn"
                  color="info"
                  fab
                  flat
                  dark
                  small
                  relative
                  :right="true"
                  @click="showCommentaryForm(props.item._id)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </span>
              <span v-else>{{ statusFR(props.item.status) }}</span>
            </td>
            <td class="layout justify-center">
              <v-btn
                class="btn"
                color="primary"
                fab
                flat
                small
                dark
                relative
                :right="true"
                @click="showInfoForm(props.item._id)"
              >
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn
                v-if="!isBRED && !isDirector && !isViewer"
                class="btn"
                color="info"
                fab
                flat
                small
                dark
                relative
                :right="true"
                @click="showEditForm(props.item._id)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                v-if="isBRED && !isViewer"
                class="btn"
                color="info"
                fab
                flat
                small
                dark
                relative
                :right="true"
                @click="showUpdateHostForm(props.item)"
              >
                <v-icon>person</v-icon>
              </v-btn>
              <v-btn
                v-if="!isViewer"
                class="btn"
                color="error"
                fab
                flat
                small
                dark
                relative
                :right="true"
                @click="deleteApplicationDialog=props.item._id"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-center">{{ props.item.guest.identity.gender }}</td>
            <td class="text-xs-center">{{ props.item.type }}</td>
            <td
              class="text-xs-center"
            >{{ props.item.guest.identity.lastName }} {{ props.item.guest.identity.firstName }}</td>
            <td class="text-xs-center">{{ props.item.guest.professionalAddress.country }}</td>
            <td class="text-xs-center">{{ props.item.guest.professionalAddress.institution }}</td>
            <td class="text-xs-center">{{ props.item.guest.identity.position }}</td>
            <td class="text-xs-center">{{ props.item.project.title }}</td>
            <td
              class="text-xs-center"
            >{{ props.item.host.lastName }} {{ props.item.host.firstName }}</td>
            <td class="text-xs-center">{{ props.item.stayDate.start | getDate }}</td>
            <td class="text-xs-center">{{ props.item.stayDate.end | getDate }}</td>
            <td class="text-xs-center">{{ props.item.transportationCost }}</td>

            <template v-if="isBRED || isAdmin">
              <td class="text-xs-center">{{ props.item.vote.adhoc.days }}</td>
              <td class="text-xs-center">{{ props.item.vote.adhoc.transportationCost }}</td>
              <td class="text-xs-center">{{ props.item.vote.adhoc.remark }}</td>

              <td class="text-xs-center">{{ props.item.vote.research.days }}</td>
              <td class="text-xs-center">{{ props.item.vote.research.transportationCost }}</td>
              <td class="text-xs-center">{{ props.item.vote.research.remark }}</td>
            </template>
          </tr>
        </template>
        <v-alert
          slot="no-results"
          :value="true"
          color="error"
          icon="warning"
        >Nous ne trouvons rien avec "{{ search[lab] }}" comme clé :(.</v-alert>
        <v-alert
          slot="no-data"
          :value="true"
          color="light-blue"
          icon="info"
        >Il semblerait qu'il n'y ait pas encore de dossier créé.</v-alert>
      </v-data-table>
    </v-card>

    <!-- Commentary form -->
    <v-dialog :value="commentaryDialog" max-width="900px" persistent scrollable>
      <CommentaryForm
        :id="applicationId"
        @close="closeApplicationDialogs"
        @success="refreshApplicationView"
      />
    </v-dialog>

    <v-dialog :value="applicationDialog" persistent>
      <ApplicationForm
        :editApplicationId="applicationId"
        @close="closeApplicationDialogs"
        @success="refreshApplicationView"
      />
    </v-dialog>

    <v-dialog :value="infoApplicationDialog" max-width="900px" persistent>
      <ApplicationInfo :id="applicationId" @close="closeApplicationDialogs" />
    </v-dialog>

    <v-dialog :value="updateHost.show" max-width="1200px" persistent scrollable>
      <UpdateHost :application="updateHost.application" :oldHost="updateHost.oldHost" @close="updateHost.show=false" @success="hostUpdated" />
    </v-dialog>

    <v-dialog :value="deleteApplicationDialog" max-width="500px" :scrollable="false" persistent>
      <template>
        <v-card>
          <v-card-title class="headline primary--text">Suppression de dossier</v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12>
                <p>Êtes-vous sur de vouloir supprimer ce dossier ?</p>
                <p>Attention une fois le dossier supprimé, il sera impossible de le récupérer !!</p>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12>
                <v-btn
                  color="secondary"
                  dark
                  small
                  @click="deleteApplication(deleteApplicationDialog)"
                >Oui</v-btn>
                <v-btn color="primary" dark small @click="deleteApplicationDialog=false">Non</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- Progress line -->
    <loading-widget v-model="loading" message="Attendez un instant, s'il vous plaît." />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { saveAs } from "file-saver";
import ApplicationForm from "@/components/application/ApplicationForm.vue";
import ApplicationInfo from "@/components/application/ApplicationInfo.vue";
import CommentaryForm from "@/components/application/CommentaryForm.vue";
import LoadingWidget from "@/components/utils/Loading.vue";
import UpdateHost from "@/components/application/UpdateHost";
import moment from "moment";

export default {
  name: "Application",
  components: {
    ApplicationForm,
    ApplicationInfo,
    LoadingWidget,
    CommentaryForm,
    UpdateHost
  },
  data: () => ({
    search: {},
    applicationId: null,
    applicationDialog: false,
    deleteApplicationDialog: false,
    infoApplicationDialog: false,
    commentaryDialog: false,
    updateHost: {
      show: false,
      oldHost: "",
      application: null
    },
    loading: false,
    campaigns: [],
    pagination: {
      sortBy: "datesApplication.opening.substring(0, 4)"
    },
    selected: [],
    headers: [
      { text: "Rang", value: "rank" },
      { text: "Statut", value: "status" },
      { text: "Actions", value: "_id" },
      { text: "Civilité", value: "guest.identity.gender" },
      { text: "Type", value: "type" },
      { text: "Invité", value: "guest.identity.lastName" },
      { text: "Pays", value: "guest.professionalAddress.country" },
      { text: "Établissement d'origine", value: "guest.professionalAddress.institution" },
      { text: "Position actuelle", value: "guest.identity.position" },
      { text: "Titre du projet", value: "project.title" },
      { text: "Invitant", value: "host.lastName" },
      { text: "Début de séjour", value: "stayDate.start" },
      { text: "Fin de séjour", value: "stayDate.end" },
      { text: "Montant de transport", value: "transportationCost" },
      { text: "Nombre de jour voté par la commission ADHOC", value: "vote.adhoc.days" },
      { text: "Frais de transports voté par la commission ADHOC", value: "vote.adhoc.transportationCost" },
      { text: "Commentaire de la commission ADHOC", value: "vote.adhoc.remark" },
      { text: "Nombre de jour voté par la commission de recherche", value: "vote.research.days" },
      { text: "Frais de transports voté par la commission de recherche", value: "vote.research.transportationCost" },
      { text: "Commentaire de la commission de recherche", value: "vote.research.remark" }
    ],
    applications: [],
    currentCampaign: null
  }),
  filters: {
    getYear (stringDate) {
      if (!stringDate) return "";
      return stringDate.substring(0, 4);
    }
  },
  mounted () {
    this.$axios.get("auth/loggedin")
      .then(async () => {
        await this.fetchCurrentCampaign();
        await this.fetchAllCampaigns();
        await this.fetchApplications();
      })
      .catch(() => {
        this.showSnackbar({
          text: "Oups ! il semble que votre session ait expirée :(",
          type: "error",
          multiline: true
        });
        this.$store.dispatch("logout")
          .then(() => this.$router.push({ name: "home" }));
      });
  },
  computed: {
    ...mapGetters([
      "snackbarVisible",
      "user" // username of the logged user
    ]),
    filteredHeaders () {
      if (this.isBRED || this.isAdmin) {
        return this.headers;
      }

      const ignoredColumns = [
        "Nombre de jour voté par la commission ADHOC",
        "Frais de transports voté par la commission ADHOC",
        "Commentaire de la commission ADHOC",
        "Nombre de jour voté par la commission de recherche",
        "Frais de transports voté par la commission de recherche",
        "Commentaire de la commission de recherche"
      ];

      if (this.isDirector || this.isViewer) {
        // no more ignored columns
      } else {
        ignoredColumns.push("Rang");
      }

      return this.headers.filter(h => !ignoredColumns.includes(h.text));
    },
    isViewer () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "VIEWER"));
    },
    isAdmin () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "ADMIN"));
    },
    /**
     * Check if user has research committee role or ad-hoc committee role
     */
    isBRED () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "BRED"));
    },
    /**
     * Check if user has research committee role or ad-hoc committee role
     */
    isDirector () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "DIRECTOR"));
    },
    deadlineRanking () {
      if (this.currentCampaign.datesCommittee.adHoc >= new Date().toISOString()) return true;
      return false;
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Fetch all the campaigns
     */
    fetchAllCampaigns () {
      return this.$axios.get("/campaign")
        .then(response => { this.campaigns = response.data; })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },
    /**
     * Fetch all the created applications
     */
    fetchApplications () {
      this.loading = true;

      return this.$axios.get("/application", { params: { group: true, campaign: this.currentCampaign._id } })
        .then(response => {
          this.applications = response.data;
          delete this.applications["BRED"];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },
    campaignLabel (campaign) {
      const opening = moment(campaign.datesCampaign.opening);
      return opening.format("MM/YYYY");
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    /**
     * Deletes an application
     * @param {string} id - application's id
     */
    async deleteApplication (id) {
      try {
        await this.$axios.delete("/application/" + id);
        this.deleteApplicationDialog = false;
        await this.fetchApplications();
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Shows the dialog for uploading a call document
     * @param {string} id - application's id
     */
    showUploadDialog (id) {
      this.applicationId = id;
      this.$refs.inputUpload.click();
    },
    /**
     * Shows the informations of a application
     * @param {string} id - application's id
     */
    showInfoForm (id) {
      this.applicationId = id;
      this.infoApplicationDialog = true;
    },
    /**
     * Shows the form for editing a application
     * @param {string} id - application's id
     */
    showEditForm (id) {
      this.applicationId = id;
      this.applicationDialog = true;
    },
    /**
     * Shows the dialog for updating host of an application
     * @param {Object} application - application's information
     */
    showUpdateHostForm (application) {
      this.updateHost.oldHost = application.host.lastName.toUpperCase() + " " + application.host.firstName;
      this.updateHost.application = application;
      this.updateHost.show = true;
    },
    /**
     * Shows the form for creating a application
     */
    showCreateForm () {
      this.applicationId = undefined;
      this.applicationDialog = true;
    },
    /**
     * Shows the form for editing the comments of an application
     * @param {string} id - application's id
     */
    showCommentaryForm (id) {
      this.applicationId = id;
      this.commentaryDialog = true;
    },
    /**
     * change the background color of the row according to its status
     * @param {string} status - application's status
     */
    getStatus (status) {
      if (status === "accepted") return "honeydew";
      else if (status === "rejected") return "mistyrose";
      return "";
    },
    /**
     * Update the status of an application
     * @param {string} id - application's id
     * @param {string} status - application's new status
     */
    async setApplication (id, status) {
      try {
        await this.$axios.put("/application/status/" + id, { status });

        await this.fetchApplications();

        if (status === "accepted") {
          await this.$axios.post("/applicationManagement", { application: id, bankInformation: "", stays: [] });
        } else {
          await this.$axios.delete("/applicationManagement/" + id);
        }
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Update the ranking of applications
     */
    saveRanking (applications) {
      this.$axios.put("/application/rank", { applications })
        .then(() => this.fetchApplications())
        .catch(error => {
          this.showSnackbar({
            text: error.response.data.error.message,
            type: "error",
            multiline: true
          });
        });
    },
    /**
     * Return application status
     */
    statusFR (status) {
      if (status === "accepted") return "Accepté";
      else if (status === "rejected") return "Refusé";
      else return "En attente";
    },
    /**
     * Export the data table to an CSV file
     */
    async getProjectsCSV () {
      try {
        this.loading = true;
        const result = await this.$axios.get("/application/download/csv", { responseType: "arraybuffer", params: { campaign: this.currentCampaign._id } });
        const blob = new Blob([result.data], { type: "application/octet-stream" });
        this.loading = false;

        saveAs(blob, "projects_csv.zip");
      } catch (err) {
        console.log("Error in getProjects: ", err);
      }
    },
    /**
     * Fetch the current campaign
     */
    async fetchCurrentCampaign () {
      try {
        const result = await this.$axios.get("/campaign/current/");
        this.currentCampaign = result.data;
      } catch (err) {
        console.log("Error in getCurrentCampaign: ", err);
      }
    },
    closeApplicationDialogs () {
      this.applicationDialog = false;
      this.infoApplicationDialog = false;
      this.commentaryDialog = false;
      this.applicationId = null;
    },
    refreshApplicationView () {
      this.closeApplicationDialogs();
      this.fetchApplications();
    },
    async getProjects () {
      try {
        this.loading = true;
        const result = await this.$axios.get("/application/download", { responseType: "arraybuffer", params: { campaign: this.currentCampaign._id } });
        const blob = new Blob([result.data], { type: "application/octet-stream" });
        this.loading = false;

        saveAs(blob, "projects.zip");
      } catch (err) {
        console.log("Error in getProjects: ", err);
      }
    },
    hostUpdated () {
      this.updateHost.show = false;
      this.fetchApplications();
    }
  }
};
</script>

<style lang="scss" >
.selectLeft .v-input__append-inner {
  float: left;
  position: absolute;
}

.selectLeft .v-select__selections, .selectLeft .v-label {
  margin-left: 2.5em;
}

.btn {
  margin: 0%;
}

.rankTextField {
  min-width: 120px;
  input {
    text-align: center;
  }
}

.buttons-table {
  position: relative;
  bottom: 55px;
}

.buttons-actions {
  min-width: 220px;
}
.buttons-status {
  min-width: 200px;
}
</style>

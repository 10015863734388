<template>
  <v-dialog :value="value" max-width="500px" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Upload Files</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md class="pa-1">
          <!-- Information note input -->
          <upload-file
            label="Note d'information"
            accept=".pdf"
            @selected="updateInformationNote"
          />

          <!-- Payment form input -->
          <upload-file
            label="Formulaire de paiement"
            accept=".docx"
            @selected="updatePaymentForm"
          />

          <!-- New agent form input -->
          <upload-file
            label="Formulaire de nouveau agent"
            accept=".docx"
            @selected="updateRegistrationForm"
          />
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-layout row>
        <v-spacer />
        <v-btn id="submit" color="primary" @click.prevent="submit"
          >Envoyer</v-btn
        >
        <v-btn color="secondary" @click="close">Fermer</v-btn>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import UploadFile from "@/components/utils/UploadFile.vue";

export default {
  props: ["value", "campaignId"],
  components: {
    UploadFile
  },
  data: () => ({
    informationNote: null,
    agentRegistrationForm: null,
    paymentForm: null
  }),
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Close dialog
     */
    close () {
      this.$emit("input", false);
    },
    /**
     * Update information note
     */
    updateInformationNote (file) {
      this.informationNote = file;
    },
    /**
     * Update payment form
     */
    updatePaymentForm (file) {
      this.paymentForm = file;
    },
    /**
     * Update new agent form
     */
    updateRegistrationForm (file) {
      this.agentRegistrationForm = file;
    },
    /**
     * Upload files to the server
     */
    async submit () {
      try {
        const formData = new FormData();
        formData.append("informationNote", this.informationNote);
        formData.append("agentRegistrationForm", this.agentRegistrationForm);
        formData.append("paymentForm", this.paymentForm);

        const campaign = await this.$axios.put(
          `/campaign/upload/information/${this.campaignId}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        this.showSnackbar({
          text: "Parfait !\nVos documents ont bien été ajoutés.",
          type: "success",
          multiline: true
        });

        this.$emit("submitted", campaign);
        this.close();
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nErreur: ",
          type: "error",
          multiline: true
        });
      }
    }
  }
};
</script>

<template>
  <v-container fluid>
    <h1 class="display-2 mb-5">Utilisateurs</h1>

    <v-card class="mb-3">
      <!-- Search bar -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-divider />

      <!-- user table -->
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :pagination.sync="pagination"
        class="elevation-1"
      >
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr>
            <td class="text-xs-center">
              <!-- Edit button -->
              <v-btn
                class="btn"
                color="info"
                fab
                flat
                small
                dark
                @click="showEditForm(props.item._id)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <!-- Delete button -->
              <v-btn
                class="btn"
                color="error"
                fab
                flat
                small
                dark
                @click="deleteUser(props.item._id)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
              <!-- Switch user button -->
              <v-btn
                class="btn"
                color="orange"
                fab
                flat
                small
                dark
                @click="switchUser(props.item._id)"
              >
                <v-icon>supervised_user_circle</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-left">{{ props.item.username }}</td>
            <td class="text-xs-left">{{ props.item.lastName }}</td>
            <td class="text-xs-left">{{ props.item.firstName }}</td>
            <td class="text-xs-left">{{ props.item.email }}</td>
            <td class="text-xs-left">{{ props.item.institution.laboratory.acronym }}</td>
            <td class="text-xs-center">
              <v-chip
                v-for="role in props.item.roles"
                :key="role._id"
                color="primary"
                outline
                small
              >{{ role.name }}</v-chip>
            </td>
          </tr>
        </template>
        <v-alert
          slot="no-results"
          :value="true"
          color="error"
          icon="warning"
        >Nous ne trouvons rien avec "{{ search }}" comme clé :(.</v-alert>
        <v-alert
          slot="no-data"
          :value="true"
          color="light-blue"
          icon="info"
        >Il semblerait qu'il n'y ait pas encore d'utilisateur créé.</v-alert>
      </v-data-table>
    </v-card>

    <!-- edit form -->
    <UserForm v-model="editUserDialog" :user="selectedUser" @success="fetchUsers" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserForm from "@/components/user/UserForm.vue";

export default {
  name: "User",
  components: {
    UserForm
  },
  data: () => ({
    search: "",
    editUserDialog: false,
    selectedUser: null,
    pagination: {
      sortBy: "lastName",
      rowsPerPage: -1
    },
    headers: [
      { text: "Actions", value: "_id" },
      { text: "Identifiant", value: "username" },
      { text: "Nom", value: "firstName" },
      { text: "Prénom", value: "lastName" },
      { text: "Courriel", value: "email" },
      { text: "Laboratoire", value: "institution.laboratory.acronym" },
      { text: "Rôle", value: "roles" }
    ],
    users: []
  }),
  mounted () {
    this.$axios.get("auth/loggedin")
      .then(() => this.fetchUsers())
      .catch(() => {
        this.showSnackbar({
          text: "Oups ! il semble que votre session ait expirée :(",
          type: "error",
          multiline: true
        });
        this.$store.dispatch("logout")
          .then(() => this.$router.push({ name: "home" }));
      });
  },
  computed: {
    ...mapGetters([
      "snackbarVisible",
      "user" // username of the logged user
    ])
  },
  methods: {
    ...mapActions(["showSnackbar", "login", "fakeLogin"]),
    /**
     * Fetch all the created users
     */
    async fetchUsers () {
      try {
        const { data } = await this.$axios.get("/user");
        this.users = data;
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Change the order of the table's rows
     */
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    /**
     * Deletes a user
     * @param {string} id - user's id
     */
    async deleteUser (id) {
      try {
        // wait for confirmation
        const confirmation = await this.$root.$confirm(
          "Suppression d'un utilisateur",
          "Êtes-vous sur de vouloir supprimer cet utilisateur ?", // message
          { color: "primary" } // color
        );

        if (confirmation) {
          await this.$axios.delete("/user/" + id);
          await this.fetchUsers();
        }
      } catch (err) {
        console.log(err);
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    async switchUser (uid) {
      await this.fakeLogin({
        id: uid
      });
      await this.login();
      await this.$router.push({ name: "home" });
    },
    /**
     * Shows the form for editing a user
     * @param {Object} userId - identifier of the selected user
     */
    showEditForm (userId) {
      this.selectedUser = userId;
      this.editUserDialog = true;
    }
  }
};
</script>

<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-5">
      <!-- Title -->
      <h1 class="display-2">Dossiers Acceptés</h1>
      <!-- download CSV -->
      <v-btn
        v-if="isBRED || isAdmin"
        color="secondary"
        class="ml-0 mt-0"
        dark
        @click="getProjectsCSV"
      >
        CSV
        <v-icon right dark>save_alt</v-icon>
      </v-btn>
    </v-flex>

    <!-- download documents -->
    <v-flex xs12 class="mb-5">
      <v-card>
        <v-card-text>
          <v-layout align-center justify-center row fill-height>
            <!-- information note -->
            <v-flex shrink>
              <v-btn
                color="info"
                class="button-info"
                outline
                dark
                @click.prevent="downloadCampaignForm('informationNote')"
              >
                Note d'Information
                <v-icon right>get_app</v-icon>
              </v-btn>
            </v-flex>

            <!-- payment form -->
            <v-flex shrink>
              <v-btn
                color="info"
                class="button-info"
                outline
                dark
                @click.prevent="downloadCampaignForm('paymentForm', 'docx')"
              >
                Formulaire de Paiement
                <v-icon right>get_app</v-icon>
              </v-btn>
            </v-flex>

            <!-- user creation form -->
            <v-flex shrink>
              <v-btn
                color="info"
                class="button-info"
                outline
                dark
                @click.prevent="downloadCampaignForm('agentRegistrationForm', 'docx')"
              >
                Formulaire de Création d'Agent
                <v-icon right>get_app</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- List of laboratories with their accepted applications -->
    <v-flex
      xs12
      class="mb-2"
      v-for="[lab, applicationsLab] in Object.entries(acceptedApplications)"
      :key="lab"
    >
      <v-card v-if="applicationsLab.length">
        <!-- laboratory name -->
        <v-card-title>
          <h1 class="primary--text">{{ lab }}</h1>
        </v-card-title>

        <!-- laboratory applications -->
        <v-card-text>
          <v-container fluid fill-height>
            <v-layout row wrap>
              <v-flex
                xs12
                v-for="application in applicationsLab"
                :key="application.application._id"
              >
                <!-- host name -->
                <h2 class="primary--text" v-if="application">
                  {{application.application.host.lastName.toUpperCase()}} {{application.application.host.firstName}}
                  <v-btn
                    :disabled="!application.bankInformation"
                    color="info"
                    class="button-info"
                    outline
                    dark
                    @click.prevent="downloadBankInformation(application.application._id)"
                  >
                    Information bancaires
                    <v-icon right>get_app</v-icon>
                  </v-btn>
                </h2>

                <!-- list of host's 'applications  -->
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="[application]"
                  :pagination.sync="pagination"
                  select-all
                  item-key="name"
                >
                  <!-- header customization -->
                  <template v-slot:headers="props">
                    <tr>
                      <th
                        v-for="header in props.headers"
                        :key="header.text"
                        :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                        @click="changeSort(header.value)"
                      >
                        <v-icon small>arrow_upward</v-icon>
                        {{ header.text }}
                      </th>
                    </tr>
                  </template>

                  <!-- stay information of the application -->
                  <template v-slot:items="props">
                    <tr v-for="(stay,index) in props.item.stays" :key="index">
                      <!-- Actions -->
                      <td class="text-xs-center">
                        <v-btn
                          color="info"
                          flat
                          icon
                          @click.prevent="editStay(props.item._id, stay)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                        <v-btn
                          color="secondary"
                          flat
                          icon
                          @click.prevent="deleteStay(props.item._id, stay.key)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>

                      <!-- stay index -->
                      <td class="text-xs-center">{{ index+1 }}</td>

                      <!-- stay start date -->
                      <td class="text-xs-center">{{ stay.start | getDate }}</td>

                      <!-- stay end date -->
                      <td class="text-xs-center">{{ stay.end | getDate }}</td>

                      <!-- PVI Actions -->
                      <td class="text-xs-center">
                        <!-- generate PVI -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="!isBRED"
                              :color="documentStatus(stay.pvi)"
                              class="button-info"
                              fab
                              small
                              outline
                              @click.prevent="generateDocument(application, stay,'pvi')"
                              v-on="on"
                            >
                              <v-icon>autorenew</v-icon>
                            </v-btn>
                          </template>
                          <span>Generate PVI</span>
                        </v-tooltip>

                        <!-- Upload signed PVI -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="!isBRED || stay.pvi.status === 'untreated'"
                              :color="documentStatus(stay.pvi)"
                              class="button-info"
                              fab
                              small
                              outline
                              v-on="on"
                              @click.prevent="showUploadDocumentDialog(application.application._id, stay.key, 'pvi')"
                            >
                              <v-icon>cloud_upload</v-icon>
                            </v-btn>
                          </template>
                          <span>Signed PVI</span>
                        </v-tooltip>

                        <!-- Download PVI -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="stay.pvi.status === 'untreated'"
                              :color="documentStatus(stay.pvi)"
                              class="button-info"
                              fab
                              small
                              outline
                              v-on="on"
                              @click.prevent="downloadDocument(application.application._id, stay.key, 'pvi')"
                            >
                              <v-icon>get_app</v-icon>
                            </v-btn>
                          </template>
                          <span>Download PVI</span>
                        </v-tooltip>
                      </td>

                      <!-- Invitation letter actions -->
                      <td class="text-xs-center">
                        <!-- Generate invitation letter (French) -->
                        <v-btn
                          :disabled="!isBRED"
                          :color="documentStatus(stay.invitationLetter.fr)"
                          class="button-info"
                          fab
                          small
                          outline
                          @click.prevent="generateDocument(application, stay, 'invitationLetter.fr')"
                        >
                          <v-icon>autorenew</v-icon>
                        </v-btn>

                        <!-- Upload signed invitation letter (French) -->
                        <v-btn
                          :disabled="!isBRED || stay.invitationLetter.fr.status === 'untreated'"
                          :color="documentStatus(stay.invitationLetter.fr)"
                          class="button-info"
                          small
                          outline
                          @click.prevent="showUploadDocumentDialog(application.application._id, stay.key, 'invitationLetter.fr')"
                        >
                          FR
                          <v-icon right>cloud_upload</v-icon>
                        </v-btn>

                        <!-- Download invitation letter (French ) -->
                        <v-btn
                          :disabled="stay.invitationLetter.fr.status === 'untreated'"
                          :color="documentStatus(stay.invitationLetter.fr)"
                          class="button-info"
                          small
                          outline
                          @click.prevent="downloadDocument(application.application._id, stay.key, 'invitationLetter.fr')"
                        >
                          FR
                          <v-icon right>get_app</v-icon>
                        </v-btn>

                        <v-spacer />

                        <!-- Generate invitation letter (English) -->
                        <v-btn
                          :disabled="!isBRED"
                          :color="documentStatus(stay.invitationLetter.en)"
                          class="button-info"
                          fab
                          small
                          outline
                          @click.prevent="generateDocument(application, stay, 'invitationLetter.en')"
                        >
                          <v-icon>autorenew</v-icon>
                        </v-btn>

                        <!-- Upload signed invitation letter (English) -->
                        <v-btn
                          :disabled="!isBRED || stay.invitationLetter.en.status === 'untreated'"
                          :color="documentStatus(stay.invitationLetter.en)"
                          class="button-info"
                          small
                          outline
                          @click.prevent="showUploadDocumentDialog(application.application._id, stay.key, 'invitationLetter.en')"
                        >
                          EN
                          <v-icon right>cloud_upload</v-icon>
                        </v-btn>

                        <!-- Download invitation letter (English) -->
                        <v-btn
                          :disabled="stay.invitationLetter.en.status === 'untreated'"
                          :color="documentStatus(stay.invitationLetter.en)"
                          class="button-info"
                          small
                          outline
                          @click.prevent="downloadDocument(application.application._id, stay.key, 'invitationLetter.en')"
                        >
                          EN
                          <v-icon right>get_app</v-icon>
                        </v-btn>
                      </td>

                      <td class="text-xs-center">
                        <!-- Download certificate -->
                        <v-btn
                          :disabled="stay.certificateServiceDone.status !== 'filed'"
                          :color="documentStatus(stay.certificateServiceDone)"
                          class="button-info"
                          fab
                          small
                          outline
                          @click.prevent="downloadDocument(application.application._id, stay.key, 'certificateServiceDone')"
                        >
                          <v-icon>get_app</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <!-- no data message -->
                  <v-alert
                    slot="no-data"
                    :value="true"
                    color="light-blue"
                    icon="info"
                  >Il semblerait qu'il n'y ait pas de dossier.</v-alert>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- Dialog to upload file -->
    <input
      id="document"
      v-show="false"
      ref="inputDocument"
      type="file"
      accept=".pdf"
      :name="documentDialogInfo.document"
      @change="documentSave"
    />

    <!-- form to edit a stay -->
    <stay-form
      v-model="showEditStay"
      :stay="selectedStay"
      :currentCampaign="currentCampaign"
      @success="fetchApplications"
    />

    <!-- Progress line -->
    <loading-widget v-model="loading" message="Attendez un instant, s'il vous plaît." />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { saveAs } from "file-saver";
import LoadingWidget from "@/components/utils/Loading.vue";
import StayForm from "@/components/applicationManagement/StayForm.vue";
const moment = require("moment");

export default {
  name: "Application",
  components: {
    LoadingWidget,
    StayForm
  },
  props: {
    applicationManagement: {
      type: [Object, Array]
    },
    currentCampaign: {
      type: Object
    }
  },
  data: () => ({
    loading: false,
    pagination: {
      sortBy: "start.substring(0, 4)"
    },
    selected: [],
    headers: [
      { text: "Actions", value: "key" },
      { text: "Numéro de séjour", value: "key" },
      { text: "Date de départ", value: "start.substring(0, 4)" },
      { text: "Date de fin", value: "end.substring(0, 4)" },
      { text: "PVI", value: "key" },
      { text: "Lettre d'invitation", value: "key" },
      { text: "Attestation de service fait", value: "key" }
    ],
    documentDialogInfo: {},
    showEditStay: false,
    selectedStay: null
  }),
  computed: {
    ...mapGetters([
      "snackbarVisible",
      "user" // username of the logged user
    ]),
    isBRED () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "BRED"));
    },
    isAdmin () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "ADMIN"));
    },
    isDirector () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "DIRECTOR"));
    },
    isViewer () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "VIEWER"));
    },
    acceptedApplications () {
      return Object.entries(this.applicationManagement)
        .reduce((res, [lab, applications]) => { if (applications.length) res[lab] = applications; return res; }, {});
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),

    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    documentStatus (doc) {
      if (doc.status === "untreated") return "red";
      else if (doc.status === "generated") return "orange";
      else return "green";
    },
    async generateDocument (application, stay, document) {
      try {
        const days = moment(stay.end).diff(moment(stay.start), "days") + 1;

        let totalAmount = this.currentCampaign.stay.amountPerDay * days;
        totalAmount = Math.round(100 * totalAmount) / 100;

        const template = document.replace(/\.(en|fr)$/i, w => w.replace(".", "").toUpperCase());

        const form = {
          stay,
          days,
          totalAmount,
          document,
          application: application.application,
          campaign: this.currentCampaign
        };

        const response = await this.$axios.post(
          `/applicationManagement/generate/${template}`, form, { responseType: "arraybuffer" });
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Upload generated file
        this.documentDialogInfo = {
          application: application.application._id,
          stay: stay.key,
          status: "generated",
          document
        };
        const file = new File([blob], `${template}.pdf`, { lastModified: new Date() });
        await this.documentSave({ target: { files: [file] } });

        saveAs(blob, `${template}.pdf`);
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     *
     */
    showUploadDocumentDialog (application, stay, document) {
      this.documentDialogInfo = { application, stay, document, status: "filed" };
      this.$refs.inputDocument.click();
    },
    fetchApplications () {
      this.$emit("updated");
    },
    /**
     *
     */
    documentSave (documentFile) {
      if (documentFile.target.files[0]) {
        const formData = new FormData();
        const { document, application, stay, status } = this.documentDialogInfo;
        formData.append(document, documentFile.target.files[0]);

        const url = `/applicationManagement/upload/${document}/${application}/${stay}/${status}`;
        return this.$axios.put(url, formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.showSnackbar({
              text: "Parfait !\nVotre document a bien été ajouté.",
              type: "success",
              multiline: true
            });
          })
          .then(() => this.fetchApplications())
          .catch(() => {
            this.showSnackbar({
              text: "Oups !\nErreur: ",
              type: "error",
              multiline: true
            });
          });
      };

      this.documentDialogInfo = {};
    },

    /**
     *
     */
    downloadDocument (application, key, document) {
      return this.$axios.get(`/applicationManagement/download/${document}/${application}/${key}`, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const filename = document.replace(/\.(en|fr)$/i, w => w.replace(".", "").toUpperCase());
          saveAs(blob, `${filename}.pdf`);
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups !\nErreur",
            type: "error",
            multiline: true
          });
        });
    },
    downloadBankInformation (id) {
      return this.$axios.get(`/applicationManagement/download/bankInformation/${id}`, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          saveAs(blob, "bankInformation.pdf");
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups !\nErreur",
            type: "error",
            multiline: true
          });
        });
    },
    async downloadCampaignForm (doc, format = "pdf") {
      try {
        const response = await this.$axios.get(`/campaign/download/${this.currentCampaign._id}/${doc}`, { responseType: "blob" });
        const blob = new Blob([response.data], { type: `application/${format}` });
        saveAs(blob, `${doc}.${format}`);
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nErreur",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Get a zip file with all the accepted projects in csv format grouped by
     * laboratory.
     */
    async getProjectsCSV () {
      try {
        this.loading = true;
        const result = await this.$axios.get("/applicationManagement/download/csv", { responseType: "arraybuffer" });
        const blob = new Blob([result.data], { type: "application/octet-stream" });
        this.loading = false;

        saveAs(blob, "accepted_projects_csv.zip");
      } catch (err) {
        console.log("Error in getProjects: ", err);
      }
    },
    /**
     * Delete a stay
     */
    async deleteStay (applicationId, stayId) {
      try {
        // wait for confirmation
        const confirmation = await this.$root.$confirm(
          "Suppression d'un séjour",
          "Êtes-vous sur de vouloir supprimer ce séjour ?",
          { color: "primary" }
        );
        if (!confirmation) return;

        await this.$axios.delete(`/applicationManagement/${applicationId}/stay/${stayId}`);

        this.showSnackbar({
          text: "Le séjour a été supprimé avec succes!",
          multiline: true,
          type: "success"
        });

        await this.fetchApplications();
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Edit a stay
     */
    async editStay (applicationId, stay) {
      this.showEditStay = true;
      this.selectedStay = {
        stay,
        application: applicationId
      };
    }
  }
};
</script>

<style lang="scss" >
.btn {
  margin: 0%;
}

.rankTextField {
  min-width: 120px;
  input {
    text-align: center;
  }
}

.buttons-table {
  position: relative;
  bottom: 55px;
}

.buttons-actions {
  min-width: 220px;
}
.buttons-status {
  min-width: 200px;
}
</style>

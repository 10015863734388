<template>
  <v-container fluid>
    <h1 class="display-2 mb-5">Campagnes</h1>

    <!-- Notice -->
    <v-layout>
      <v-card
        dark
        color="secondary"
        width="100%"
        class="mb-3"
        v-if="user && user.roles && !user.roles.find(role => role.name === 'BRED')"
      >
        <v-card-text class="warning-message subheading">
          Veuillez prendre connaissance de l'appel d'offre avant de déposer votre candidature
          <v-icon class="pl-1 pr-2">warning</v-icon>Please take notice of the call document before submitting your application
        </v-card-text>
      </v-card>
    </v-layout>

    <div style="position: relative">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="campaigns"
        :pagination.sync="pagination"
        select-all
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              align="center"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr
            :bgcolor="expired(props.item.datesCampaign.opening)"
            @dblclick="showAppliForm(props.item._id, props.item.datesCampaign.deadline)"
          >
            <td class="justify-center layout px-24">
              <v-btn
                v-if="user && user.roles && user.roles.find(role => role.name === 'BRED')"
                data-cy="updateCampaignBtn"
                color="info"
                class="ml-0"
                flat
                icon
                dark
                @click="showEditForm(props.item._id)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn
                v-if="user && user.roles && user.roles.find(role => role.name === 'BRED')"
                color="error"
                class="ml-0"
                flat
                icon
                dark
                @click="openDialog(props.item._id, 'Suppression de campagne', 'Êtes-vous sur de vouloir supprimer cette campagne ?', 'Attention une fois la campagne supprimé, il sera impossible de la récupérer !!', 'delete')"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
              <v-btn
                v-if="user && user.roles && user.roles.find(role => role.name === 'BRED') && props.item.status === 'created'"
                color="primary"
                class="ml-0"
                flat
                icon
                dark
                @click="openDialog(props.item._id, 'Lancement de campagne', 'Êtes-vous sur de vouloir lancer cette campagne ?', 'Attention une fois la campagne lancée, il sera impossible d\'annuler le lancement !!', 'launched')"
              >
                <v-icon small>play_arrow</v-icon>
              </v-btn>
              <v-btn
                v-if="!deadlinePassed(props.item.datesCampaign.deadline) && user && user.roles && !user.roles.find(role => role.name === 'BRED')"
                color="info"
                class="ml-0"
                flat
                icon
                dark
                @click="showAppliForm(props.item._id, props.item.datesCampaign.deadline)"
              >
                <v-icon small>forward</v-icon>
              </v-btn>
              <v-btn
                v-if="user && user.roles && user.roles.find(role => role.name === 'BRED') && props.item.status === 'launched'"
                color="black"
                class="ml-0"
                flat
                icon
                dark
                @click="openDialog(props.item._id, 'Arrêt de campagne', 'Êtes-vous sur de vouloir arrêter cette campagne ?', 'Attention une fois la campagne arrêtée, il sera impossible de la relancer !!', 'stop')"
              >
                <v-icon small>cancel_schedule_send</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-center">{{ props.item.datesCampaign.opening | getYear }}</td>
            <td class="text-xs-center">{{ props.item.president }}</td>
            <td class="text-xs-center">
              <v-btn
                class="Btn"
                v-if="props.item.callDocument"
                color="primary"
                fab
                flat
                small
                @click="downloadFile(props.item._id)"
              >
                <v-icon>description</v-icon>
              </v-btn>

              <v-btn
                class="Btn"
                v-if="!expired(props.item.datesCampaign.opening) && user && user.roles && user.roles.find(role => role.name === 'BRED')"
                color="primary"
                fab
                flat
                small
                @click="showUploadDialog(props.item._id)"
                type="file"
              >
                <v-icon>cloud_upload</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-center">
              <v-btn
                class="Btn"
                v-if="props.item.applicationDocuments && props.item.applicationDocuments.informationNote"
                color="primary"
                fab
                flat
                small
                @click="downloadInformationNoteFile(props.item._id)"
              >
                <v-icon>description</v-icon>
              </v-btn>

              <v-btn
                class="Btn"
                v-if="!expired(props.item.datesCampaign.opening) && user && user.roles && user.roles.find(role => role.name === 'BRED')"
                color="primary"
                fab
                flat
                small
                @click="showApplicationDocumentsUploadDialog(props.item._id)"
                type="file"
              >
                <v-icon>cloud_upload</v-icon>
              </v-btn>
            </td>
            <td
              class="text-xs-center"
            >{{ props.item.datesCampaign.publication | getDate }}</td>
            <td
              class="text-xs-center"
            >{{ props.item.datesCampaign.opening | getDate }}</td>
            <td
              class="text-xs-center"
            >{{ props.item.datesCampaign.deadline | getDate }}</td>
            <td class="text-xs-center">{{ props.item.stay.dates.start | getDate }}</td>
            <td class="text-xs-center">{{ props.item.stay.dates.end | getDate }}</td>
          </tr>
        </template>
        <v-alert
          slot="no-data"
          :value="true"
          color="light-blue"
          icon="info"
        >Il semblerait qu'il n'y ait pas encore de campagne créée.</v-alert>
      </v-data-table>
      <div class="layout justify-center buttons-table">
        <v-btn
          v-if="user && user.roles && user.roles.find(role => role.name === 'BRED')"
          color="secondary"
          dark
          small
          fab
          id="newCampaignBtn"
          data-cy="newCampaignBtn"
          @click="createCampaignDialog = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </div>
    <input
      v-show="false"
      ref="inputUpload"
      type="file"
      name="callDocument"
      accept="application/pdf"
      @change="inputFileExcel"
    />

    <v-dialog :value="createCampaignDialog || updateCampaignDialog" persistent max-width="800px">
      <CampaignForm
        :id="updateCampaignDialog ? campaignId : undefined"
        @close="createCampaignDialog ? createCampaignDialog = false : updateCampaignDialog = false"
        @success="fetchCampaigns"
      />
    </v-dialog>

    <v-dialog :value="applicationDialog" persistent>
      <ApplicationForm
        :applicationId="applicationId"
        :campaignId="campaignId"
        @close="applicationDialog = false"
      />
    </v-dialog>

    <v-dialog :value="editDialog.show" max-width="500px" :scrollable="false" persistent>
      <template>
        <v-card>
          <v-card-title class="headline primary--text">{{editDialog.title}}</v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12>
                <p>{{editDialog.subtitle}}</p>
                <p>{{editDialog.body}}</p>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-spacer />
              <v-btn color="primary" @click="updateCampaign()">Oui</v-btn>
              <v-btn color="secondary" @click="closeDialog()">Non</v-btn>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <upload-documents v-model="uploadDocumentsDialog" :campaignId="campaignId" @submitted="fetchCampaigns"/>
  </v-container>
</template>

<script>
import { saveAs } from "file-saver";
import { mapGetters, mapActions } from "vuex";
import CampaignForm from "@/components/campaign/CampaignForm.vue";
import ApplicationForm from "@/components/application/ApplicationForm.vue";
import UploadDocuments from "@/components/applicationManagement/UploadDocuments.vue";

export default {
  name: "Campaign",
  components: {
    ApplicationForm,
    CampaignForm,
    UploadDocuments
  },
  data: () => ({
    editDialog: {
      campaignNewStatus: "", // New status of the campaign: "launched" | "delete" | "stop"
      campaignNewStatusId: "", // ID of the campaign that will have a new status
      body: "",
      show: false,
      subtitle: "",
      title: ""
    },
    campaignId: null,
    applicationId: null,
    createCampaignDialog: false,
    updateCampaignDialog: false,
    uploadDocumentsDialog: false,
    applicationDialog: false,
    pagination: {
      sortBy: "datesCampaign.opening.substring(0, 4)"
    },
    selected: [],
    headers: [
      { text: "Actions", value: "_id" },
      { text: "Année", value: "datesCampaign.opening" },
      { text: "Président", value: "president" },
      { text: "Appel d'offre", value: "callDocument" },
      { text: "Note d'Information", value: "applicationDocuments.informationNote" },
      { text: "Publication de l'appel d'offre", value: "datesCampaign.publication" },
      { text: "Ouverture de l'application", value: "datesCampaign.opening" },
      { text: "Date limite de dépôt des dossiers", value: "datesCampaign.deadline" },
      { text: "Date de début de séjour", value: "stay.dates.start" },
      { text: "Date de fin de séjour", value: "stay.dates.end" }
    ],
    campaigns: []
  }),
  filters: {
    getYear (stringDate) {
      if (!stringDate) return "";
      return stringDate.substring(0, 4);
    }
  },
  mounted () {
    this.$axios.get("auth/loggedin")
      .then(() => {
        this.fetchCampaigns();
      })
      .catch(() => {
        this.showSnackbar({
          text: "Oups ! il semble que votre session ait expirée :(",
          type: "error",
          multiline: true
        });
        this.$store.dispatch("logout")
          .then(() => this.$router.push({ name: "home" }));
      });
  },
  computed: {
    ...mapGetters(["snackbarVisible", "user"])
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Fetch all the created campaigns
     */
    fetchCampaigns () {
      return this.$axios.get("/campaign")
        .then(response => {
          this.campaigns = response.data;
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
          this.$store.dispatch("logout")
            .then(() => this.$router.push({ name: "home" }));
        });
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    inputFileExcel (file) {
      if (!file) return;

      const formData = new FormData();
      formData.append("callDocument", file.target.files[0]);

      return this.$axios.put(`/campaign/upload/${this.campaignId}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(() => {
          this.fetchCampaigns();
          this.showSnackbar({
            text: "Parfait !\nVotre appel d'offre a bien été ajouté.",
            type: "success",
            multiline: true
          });
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups !\nErreur: ",
            type: "error",
            multiline: true
          });
          this.$store.dispatch("logout")
            .then(() => this.$router.push({ name: "home" }));
        });
    },
    /**
     * Shows the dialog for uploading a call document
     * @param {string} id - campaign's id
     */
    showUploadDialog (id) {
      this.campaignId = id;
      this.$refs.inputUpload.click();
    },
    /**
     * Shows the form for editing a campaign
     * @param {string} id - campaign's id
     */
    showEditForm (id) {
      this.campaignId = id;
      this.updateCampaignDialog = true;
    },
    /**
     * Shows the form for editing a campaign
     * @param {string} id - campaign's id
     */
    showAppliForm (id, deadline) {
      if (!this.deadlinePassed(deadline)) {
        this.campaignId = id;
        this.applicationId = undefined;
        this.applicationDialog = true;
      }
    },
    /**
     * Download campaign call document
     * @param {string} id - campaign's id
     */
    downloadFile (id) {
      return this.$axios.get(`/campaign/download/${id}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          saveAs(blob, "Appel d'offre.pdf");
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups !\nErreur",
            type: "error",
            multiline: true
          });
          this.$store.dispatch("logout")
            .then(() => this.$router.push({ name: "home" }));
        });
    },
    /**
     * Download the information note document
     */
    async downloadInformationNoteFile (id) {
      try {
        const response = await this.$axios.get(`/campaign/download/${id}/informationNote`, { responseType: "blob" });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, "informationNote.pdf");
      } catch (err) {
        this.showSnackbar({
          text: "Oups !\nErreur",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Check if the campaign opening date has already passed
     * @param {string} stringDate - campaign's opening date
     */
    expired (stringDate) {
      const year = stringDate.substring(0, 4);
      return (year < this.currentYear()) ? "#e6e6e6" : null;
    },
    deadlinePassed (deadline) {
      return (this.today() > deadline);
    },
    /**
     * Update a campaign status
     * @param {string} campaignId - campaign's id
     */
    async updateCampaign () {
      try {
        const campaignId = this.editDialog.campaignNewStatusId;
        switch (this.editDialog.campaignNewStatus) {
          case "launched":
            await this.$axios.put(`/campaign/launched/${campaignId}`);
            break;
          case "stop":
            await this.$axios.put(`/campaign/finish/${campaignId}`);
            break;
          case "delete":
            await this.$axios.delete(`/campaign/${campaignId}`);
            break;
          default:
            break;
        }
        await this.fetchCampaigns();
        await this.closeDialog();
      } catch (error) {
        this.showSnackbar({
          text: "Oups !\nErreur: ",
          type: "error",
          multiline: true
        });
      }
    },

    /**
     * Shows a dialog to upload application documents to the server
     */
    showApplicationDocumentsUploadDialog (campaignId) {
      this.campaignId = campaignId;
      this.uploadDocumentsDialog = true;
    },

    async openDialog (campaignId, title, subtitle, body, status) {
      this.editDialog.title = title;
      this.editDialog.subtitle = subtitle;
      this.editDialog.body = body;
      this.editDialog.campaignNewStatus = status;
      this.editDialog.campaignNewStatusId = campaignId;
      this.editDialog.show = true;
    },

    closeDialog () {
      this.editDialog.title = "";
      this.editDialog.subtitle = "";
      this.editDialog.body = "";
      this.editDialog.campaignNewStatus = "";
      this.editDialog.campaignNewStatusId = null;
      this.editDialog.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons-table {
  position: relative;
  bottom: 55px;
}
.warning-message {
  text-align: center;
}
</style>

<template>
  <v-container fluid>
    <h1 class="display-2 mb-5">Profile</h1>

    <v-layout justify-center>
      <v-flex xs12 sm10 md8 lg6>
        <v-card>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="user.firstName"
                  label="Prénom • First Name"
                  v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                  name="firstName"
                  id="hostFirstName"
                  :disabled="!edit"
                  :error-messages="errors.collect('firstName')"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="user.lastName"
                  label="Nom • Last name"
                  name="lastName"
                  id="hostLastName"
                  :disabled="!edit"
                  v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                  :error-messages="errors.collect('lastName')"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="user.email"
                  label="E-mail"
                  v-validate="'required|email'"
                  name="email"
                  id="hostEmail"
                  :disabled="!edit"
                  :error-messages="errors.collect('email')"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-autocomplete
                  v-model="user.institution.laboratory"
                  :items="laboratories"
                  label="Laboratoire d'accueil • Host laboratory"
                  v-validate="'required'"
                  name="hostLaboratory"
                  id="hostLaboratory"
                  :disabled="!edit"
                  :error-messages="errors.collect('hostLaboratory')"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="user.institution.team"
                  label="équipe de recherche • research team"
                  v-validate="'required'"
                  name="hostResearchTeam"
                  id="hostResearchTeam"
                  @input="user.institution.team = updateResearchTeam(user.institution.team)"
                  :disabled="!edit"
                  :error-messages="errors.collect('hostResearchTeam')"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider class="mt-1"></v-divider>
          <v-card-actions>
            <v-switch v-model="edit" color="primary" label="Edit" />
            <v-spacer />
            <v-btn color="primary" :disabled="!edit" @click.prevent="submit">Envoyer</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Profile",

  data: () => ({
    user: { institution: { laboratory: {} }, roles: [] },
    laboratories: [],
    edit: false
  }),

  async mounted () {
    await Promise.all([this.fetchProfile(), this.fetchLaboratories()]);
  },

  methods: {
    ...mapActions(["showSnackbar", "login"]),
    async fetchLaboratories () {
      try {
        const laboratories = await this.$axios.get("laboratory");
        this.laboratories = laboratories.data.map(l => ({ text: l.acronym, value: l._id }));
      } catch (err) {
        console.log("error in Profile, fetchLaboratories");
      }
    },
    async fetchProfile () {
      try {
        const profile = await this.$axios.get("profile");
        this.user = profile.data;
      } catch (err) {
        console.log("error in Profile: ", err);
      }
    },
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");

        const user = {
          ...this.user,
          _id: undefined
        };
        await this.$axios.put("profile", user);
        await this.login();
        await this.fetchProfile();
        this.edit = false;
      } catch (error) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    updateResearchTeam (team) {
      return team.toUpperCase().trim();
    }
  }
};
</script>

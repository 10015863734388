<template>
  <v-container class="py-0 px-1">
    <h1 class="primary--text">Données complémentaires • Additional informations</h1>
    <v-layout row wrap>
      <v-flex xs12>
        <v-textarea
          name="researchActivities"
          label="Activité de recherche de l'invité • Research activity:"
          counter
          maxlength="1000"
          v-model="form.researchActivities"
          v-validate="'required'"
          :error-messages="errors.collect('researchActivities')"
        ></v-textarea>
      </v-flex>

      <v-flex xs12>
        <GuestArray
          class="px-0"
          head="keywords"
          :array="form.keywords"
          v-on:add="addKeywords"
          v-on:delete="deleteKeywords"
        />
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex shrink>
        <v-btn
          color="primary"
          class="button-info ml-0"
          large
          dark
          @click.prevent="showUploadPhotoDialog()"
          type="file"
        >
          photo ou logo de l'institution
          <v-icon right>cloud_upload</v-icon>
        </v-btn>
      </v-flex>

      <v-flex shrink>
        <v-subheader>{{ photoName | filename }}</v-subheader>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex shrink>
        <v-btn
          color="primary"
          class="button-info ml-0"
          large
          dark
          @click.prevent="showUploadCVDialog()"
          type="file"
        >
          CV
          <v-icon right>cloud_upload</v-icon>
        </v-btn>
      </v-flex>
      <v-flex shrink>
        <v-subheader>{{ cvName | filename }}</v-subheader>
      </v-flex>
    </v-layout>

    <v-layout align-center row wrap class="my-3">
      <v-flex xs4>
        <v-subheader class="pl-0">
          Acceptez vous que la photo soit publié sur le site web de l'université ?
          <br />Do you accept that the photo be published on the university's website ?
        </v-subheader>
      </v-flex>

      <v-flex xs8>
        <v-radio-group v-model="form.photoVisible" row>
          <v-radio label="oui" :value="true"></v-radio>
          <v-radio label="non" :value="false"></v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>

    <!-- Hidden inputs files -->
    <input id="photo" v-show="false" ref="inputPhoto" type="file" name="photo" @change="photoSave" />
    <input id="cv" v-show="false" ref="inputCV" type="file" name="cv" @change="CVSave" />

    <v-divider class="my-2"></v-divider>
    <v-layout row>
      <v-spacer />
      <v-btn id="submit" color="primary" @click.prevent="submit">Envoyer</v-btn>
      <v-btn color="secondary" :id="'closeBtn'" @click="$emit('close')">Fermer</v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import GuestArray from "@/components/utils/GuestArray.vue";

export default {
  name: "CVForm",
  props: {
    editApplicationId: {
      type: String
    }
  },

  data: () => ({
    form: {
      keywords: [],
      photoVisible: true,
      researchActivities: "",
      photo: "",
      cv: ""
    },
    photoName: "",
    cvName: "",
    keywordsId: 0,
    type: "create"
  }),

  components: {
    GuestArray
  },
  filters: {
    filename: function (value) {
      if (!value) return "";
      const ret = value.split(/.*[/|\\]/)[1];
      return ret || value;
    }
  },
  watch: {
    editApplicationId (newId) {
      if (!newId) return;
      this.fetchCvForm();
    }
  },
  mounted () {
    if (!this.editApplicationId) return;
    this.fetchCvForm();
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid || this.photoName === "" || this.cvName === "" || this.keywordsId === 0) throw new Error("Invalid fields");
        this.$emit("CVForm", this.form, this.type);
      } catch (error) {
        if (this.keywordsId === 0) {
          this.showSnackbar({
            text: "Oups !\nVous devez mettre au moins un keyword",
            type: "error",
            multiline: true
          });
        } else if (this.photoName === "" || this.cvName === "") {
          this.showSnackbar({
            text: "Oups !\nVous devez impoter un CV et une photo",
            type: "error",
            multiline: true
          });
        } else {
          this.showSnackbar({
            text: "Oups !\nVérifiez vos champs",
            type: "error",
            multiline: true
          });
        }
      }
    },
    /**
     * Add a keyword to the list
     * @param {String} name - keyword's name
     */
    addKeywords (name) {
      if (this.form.keywords.find(keyword => keyword.name === name)) {
        this.showSnackbar({
          text: "Oups !\nVous ne pouvez pas mettre deux fois le même mot :(",
          type: "error",
          multiline: true
        });
        return;
      }
      this.form.keywords.push({ name: "" });
      this.form.keywords[this.keywordsId].name = name;
      this.keywordsId = this.keywordsId + 1;
    },
    /**
     * Delete a keyword from the list
     * @param {String} name - keyword's name
     */
    deleteKeywords (name) {
      var i = 0;
      this.form.keywords.forEach(award => {
        if (award.name === name) {
          if (i === this.keywordsId) {
            award.name = "";
          } else {
            this.form.keywords.splice(i, 1);
            this.keywordsId = this.keywordsId - 1;
          }
        }
        i = i + 1;
      });
    },
    /**
     * Shows the dialog for uploading a photo
     */
    showUploadPhotoDialog () {
      this.$refs.inputPhoto.click();
    },
    /**
     * Shows the dialog for uploading a CV
     */
    showUploadCVDialog () {
      this.$refs.inputCV.click();
    },
    /**
     * Save the selected photo
     * @param {File} photo - the photo
     */
    photoSave (photo) {
      // TODO: remove photo.test.target.files[0] and set photo into photo.target.files[0] (test file: client/tests/e2e/support/commands.js (l.116))
      if (photo.target.files[0]) this.form.photo = photo.target.files[0];
      this.photoName = "La photo a été importée";
    },
    /**
     * Save the selected CV
     * @param {File} cv - the CV
     */
    CVSave (cv) {
      if (cv.target.files[0]) this.form.cv = cv.target.files[0];
      this.cvName = "Le CV a été importé";
    },
    /**
     * Fetch application informations when edit application
     */
    async fetchCvForm () {
      await this.$axios.get("/application/" + this.editApplicationId)
        .then((response) => {
          this.form.keywords = response.data.keywords;
          this.form.photoVisible = response.data.photoVisible;
          this.form.researchActivities = response.data.guest.researchActivities;
          this.keywordsId = this.form.keywords.length;
          this.photoName = response.data.photo.substr(56, response.data.photo.length);
          this.cvName = response.data.cv.substr(53, response.data.cv.length);
          this.type = "update";
        })
        .catch((err) => console.log(err));
    },
    /**
     * Clear form fields
     */
    clearForm () {
      this.form.keywords = [];
      this.form.photoVisible = false;
      this.form.researchActivities = "";
      this.form.photo = "";
      this.form.cv = "";
      this.photoName = "";
      this.cvName = "";
      this.keywordsId = 0;
      this.type = "create";
    }
  }
};

</script>

<style lang="scss" scoped>
.button-info {
  min-width: 350px;
}
</style>

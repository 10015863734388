<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm10 md10>
        <v-card>
          <v-card-title class="headline primary--text">
            <h2>Créer votre compte</h2>
          </v-card-title>

          <v-card-text>
            <v-form>
              <v-container grid-list-md>
                <v-layout row>
                  <v-flex xs4 pl-3 pr-3>
                    <v-text-field
                      v-model="userForm.lastName"
                      label="Nom • Last name"
                      name="lastName"
                      id="hostLastName"
                      v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                      :error-messages="errors.collect('lastName')"
                    ></v-text-field>
                  </v-flex>

                  <v-spacer></v-spacer>

                  <v-flex xs4 pl-3 pr-3>
                    <v-text-field
                      v-model="userForm.firstName"
                      label="Prénom • First Name"
                      v-validate="'required|regex:^([a-zA-ZÀ-ÿ\\-]*)(((\\s*)([a-zA-ZÀ-ÿ\\-]*))*)?$'"
                      name="firstName"
                      id="hostFirstName"
                      :error-messages="errors.collect('firstName')"
                    ></v-text-field>
                  </v-flex>

                  <v-spacer></v-spacer>

                  <v-flex xs4 pl-3 pr-3>
                    <v-text-field
                      v-model="userForm.email"
                      label="E-mail"
                      v-validate="'required|email'"
                      name="email"
                      id="hostEmail"
                      :error-messages="errors.collect('email')"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout align-center>
                  <v-flex xs6 pl-3 pr-3>
                    <v-autocomplete
                      v-model="userForm.institution.laboratory"
                      :items="laboratories"
                      label="Laboratoire d'accueil • Host laboratory"
                      v-validate="'required'"
                      name="hostLaboratory"
                      id="hostLaboratory"
                      :error-messages="errors.collect('hostLaboratory')"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs6 pl-3 pr-3>
                    <v-text-field
                      v-model="userForm.institution.team"
                      label="équipe de recherche • research team"
                      v-validate="'required'"
                      name="hostResearchTeam"
                      id="hostResearchTeam"
                      @keyup="userForm.institution.team = updateResearchTeam(userForm.institution.team)"
                      :error-messages="errors.collect('hostResearchTeam')"
                      hint="Les espaces ne sont pas autorisés"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider class="mt-1"></v-divider>
          <v-card-actions>
            <span
              class="subheading font-weight-black ml-3"
            >Entrez vos informations et non celles de l'invité.</span>
            <v-spacer />
            <v-btn color="primary" @click.prevent="submit">Continuer</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Register",
  computed: {
    ...mapGetters([
      "user" // username of the logged user
    ])
  },
  data: () => ({
    userForm: {
      institution: {
        laboratory: "",
        team: ""
      },
      firstName: "",
      lastName: "",
      email: ""
    },
    laboratories: []
  }),
  mounted () {
    this.fetchLaboratories();
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    /**
      * Initialise all laboratories
      */
    fetchLaboratories () {
      return this.$axios.get("/register")
        .then(response => {
          this.laboratories = response.data.map(l => ({ value: l._id, text: l.acronym }));
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },
    /**
     * Submit the form
     */
    async submit () {
      try {
        const valid = await this.$validator.validate();
        if (!valid) throw new Error("Invalid fields");
        await this.$axios.post("/register", this.userForm);
        this.$router.push({ name: "login" });
      } catch (error) {
        this.showSnackbar({
          text: "Oups !\nVérifiez vos champs",
          type: "error",
          multiline: true
        });
      }
    },
    updateResearchTeam (team) {
      return team.toUpperCase().trim();
    }
  }
};
</script>

<style lang="scss">
.v-btn-toggle {
  margin-left: 25px;
}

.v-btn-toggle > .v-btn {
  margin-left: 25px;
  font-weight: bold;
}
</style>

<template>
  <v-card >
    <v-toolbar color="primary" card dark>
      <v-toolbar-title>Changer l'invitant du dossier</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-card class="mb-3" flat>
        <!-- Search bar -->
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Rechercher"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-divider />

        <!-- user table -->
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :pagination.sync="pagination"
          class="elevation-1"
        >
          <template v-slot:headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                @click="changeSort(header.value)"
              >
                <v-icon small>arrow_upward</v-icon>
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template v-slot:items="props">
            <tr @click="showValidateDialog(props.item._id, props.item.firstName, props.item.lastName)">
              <td class="text-xs-left">{{ props.item.username }}</td>
              <td class="text-xs-left">{{ props.item.lastName }}</td>
              <td class="text-xs-left">{{ props.item.firstName }}</td>
              <td class="text-xs-left">{{ props.item.email }}</td>
              <td class="text-xs-left">{{ props.item.institution.laboratory.acronym }}</td>
              <td class="text-xs-center">
                <v-chip
                  v-for="role in props.item.roles"
                  :key="role._id"
                  color="primary"
                  outline
                  small
                >{{ role.name }}</v-chip>
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="warning"
          >Nous ne trouvons rien avec "{{ search }}" comme clé :(.</v-alert>
          <v-alert
            slot="no-data"
            :value="true"
            color="light-blue"
            icon="info"
          >Il semblerait qu'il n'y ait pas encore d'utilisateur créé.</v-alert>
        </v-data-table>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="secondary" :id="'closeBtn'" @click="$emit('close')">Fermer</v-btn>
    </v-card-actions>

    <v-dialog :value="showValidationDialog" max-width="500px" :scrollable="false" persistent>
      <template>
        <v-card>
          <v-card-title class="headline primary--text">Mise à jour de l'invitant</v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12>
                <p>Êtes-vous sur de vouloir changer l'invitant <strong>{{oldHost}}</strong> par <strong>{{newHost}}</strong> ?</p>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-spacer />
              <v-btn color="primary" @click="updateHost">Oui</v-btn>
              <v-btn color="secondary" @click="showValidationDialog=false">Non</v-btn>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "User",
  props: {
    oldHost: {
      type: String
    },
    application: {
      type: Object
    }
  },
  data: () => ({
    search: "",
    pagination: {
      sortBy: "lastName",
      rowsPerPage: -1
    },
    headers: [
      { text: "Identifiant", value: "username" },
      { text: "Nom", value: "firstName" },
      { text: "Prénom", value: "lastName" },
      { text: "Courriel", value: "email" },
      { text: "Laboratoire", value: "institution.laboratory.acronym" },
      { text: "Rôle", value: "roles" }
    ],
    users: [],
    newHost: "",
    newHostId: "",
    showValidationDialog: false
  }),
  mounted () {
    this.$axios.get("auth/loggedin")
      .then(() => this.fetchUsers())
      .catch(() => {
        this.showSnackbar({
          text: "Oups ! il semble que votre session ait expirée :(",
          type: "error",
          multiline: true
        });
        this.$store.dispatch("logout")
          .then(() => this.$router.push({ name: "home" }));
      });
  },
  computed: {
    ...mapGetters([
      "snackbarVisible",
      "user" // username of the logged user
    ])
  },
  methods: {
    ...mapActions(["showSnackbar", "login", "fakeLogin"]),
    /**
     * Fetch all the created users
     */
    async fetchUsers () {
      try {
        const { data } = await this.$axios.get("/user");
        this.users = data;
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    /**
     * Change the order of the table's rows
     */
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    showValidateDialog (id, firstname, lastname) {
      this.newHostId = id;
      this.newHost = lastname.toUpperCase() + " " + firstname;
      this.showValidationDialog = true;
    },
    async updateHost () {
      try {
        const applicationId = this.application._id;

        this.application.host = this.newHostId;
        this.application.__v = undefined;
        this.application._id = undefined;
        await this.$axios.put("/application/" + applicationId, this.application);

        this.showValidationDialog = false;
        this.$emit("success");
      } catch (err) {
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    }
  }
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import fakeUser from "./modules/fakeUser";
import login from "./modules/login";
import snackbar from "./modules/snackbar";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    fakeUser,
    login,
    snackbar
  },
  state: {
    title: process.env.VUE_APP_NAME,
    version: process.env.VUE_APP_VERSION
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    title: state => state.title,
    version: state => state.version
  }
});

<template>
  <v-container fluid fill-height justify-center>
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  computed: {
    ...mapGetters([
      "user" // username of the logged user
    ])
  },
  async mounted () {
    try {
      await this.$store.dispatch("login");
      if (!this.user.email) await this.$router.push({ name: "register" });
      else await this.$router.push({ name: "home" });
    } catch (error) {
      this.$router.push({ name: "register" });
    }
  }
};
</script>

<template>
  <v-app>
    <NavBar />
    <snackbar />
    <!-- Confirm dialog -->
    <confirm-dialog ref="confirmDialog" />
    <v-content>
      <router-view />
    </v-content>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Snackbar from "@/components/Snackbar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    Snackbar
  },
  // Global access to the dialog via $root.$confirm
  mounted () {
    this.$root.$confirm = this.$refs.confirmDialog.open;
  }
};
</script>

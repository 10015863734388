<template>
  <v-footer app dark color="primary" class="pa-3">
    <v-spacer></v-spacer>
    <div>&copy; {{ currentYear() }}</div>
  </v-footer>
</template>

<script>
/**
 * The footer of the application
 */
export default {
  name: "Footer"
};
</script>

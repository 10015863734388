<template>
  <v-layout>
    <v-text-field
      :label="label"
      placeholder="Select..."
      v-model="fileName"
      prepend-icon="attach_file"
      @click="onPickFile"
    ></v-text-field>
    <!-- Hidden -->
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      :accept="accept"
      @change="onFilePicked"
    />
  </v-layout>
</template>

<script>
export default {
  props: {
    /** Label of the textfile */
    label: {
      type: String,
      required: true
    },
    /** accepted extensions */
    accept: {
      type: String,
      default: "*/*"
    }
  },
  data: () => ({
    fileName: "" // selected filename
  }),
  methods: {
    /**
     * Shows the input widget
     */
    onPickFile () {
      this.$refs.fileInput.click();
    },
    /**
     * Emits an event with the selected file
     */
    onFilePicked (event) {
      const files = event.target.files;
      this.fileName = (files[0] !== undefined) ? files[0].name : "";
      this.$emit("selected", files[0]);
    }
  }
};
</script>

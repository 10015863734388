<template>
  <v-container fluid fill-height>
    <v-layout row wrap>
      <v-flex xs12  class="mb-2">
        <!-- select campaign -->
        <v-select
          outline
          v-model="currentCampaign"
          :items="campaigns"
          :item-text="campaignLabel"
          return-object
          label="Campaign"
          class="selectLeft mt-4"
          @change="fetchApplications"
        ></v-select>
      </v-flex>

      <!-- admin/bred view -->
      <template v-if="isBRED || isAdmin">
        <Applications :applicationManagement="applications" :currentCampaign="currentCampaign" @updated="fetchApplications"/>
      </template>

      <!-- viewer view -->
      <template v-else-if="isViewer">
        <ApplicationsViewer :applications="applications" />
      </template>

      <!-- user view -->
      <template v-else-if="applicationId">
        <Form :applicationId="applicationId" @submitForm="submitForm" />
      </template>

      <!-- user has not submitted an application -->
      <template v-else>
        <v-flex xs12 class="mb-2">
          <v-card dark width="100%" color="secondary">
            <v-card-text class="warning-message subheading">
              Vous n'avez pas de dossier accépté par la commission de recherche
              <v-icon class="pl-1 pr-2">warning</v-icon>You don't have an application accepted by the research committee
            </v-card-text>
          </v-card>
        </v-flex>
      </template>

      <!-- Progress line -->
      <loading-widget v-model="loading" message="Attendez un instant, s'il vous plaît." />
    </v-layout>
  </v-container>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import LoadingWidget from "@/components/utils/Loading.vue";
import Form from "@/components/applicationManagement/Form.vue";
import Applications from "@/components/applicationManagement/Applications.vue";
import ApplicationsViewer from "@/components/applicationManagement/ApplicationsViewer.vue";
import moment from "moment";

export default {
  name: "ApplicationManagement",
  components: {
    Form,
    Applications,
    LoadingWidget,
    ApplicationsViewer
  },
  data: () => ({
    applicationId: null,
    applications: [],
    currentCampaign: null,
    campaigns: [],
    loading: false
  }),
  mounted () {
    this.$axios.get("auth/loggedin")
      .then(async () => {
        await this.fetchCurrentCampaign();
        await this.fetchAllCampaigns();
        await this.fetchApplications();
      })
      .catch(() => {
        this.showSnackbar({
          text: "Oups ! il semble que votre session ait expirée :(",
          type: "error",
          multiline: true
        });
        this.$store.dispatch("logout")
          .then(() => this.$router.push({ name: "home" }));
      });
  },
  computed: {
    ...mapGetters(["snackbarVisible", "user"]),
    /**
     * Check if user has research committee role or ad-hoc committee role
     */
    isBRED () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "BRED"));
    },
    /**
     * Check if user has research committee role or ad-hoc committee role
     */
    isDirector () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "DIRECTOR"));
    },
    isAdmin () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "ADMIN"));
    },
    isViewer () {
      return (this.user && this.user.roles && this.user.roles.find(role => role.name === "VIEWER"));
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    /**
     * Fetch the current campaign
     */
    async fetchCurrentCampaign () {
      try {
        const result = await this.$axios.get("/campaign/current/");
        this.currentCampaign = result.data;
      } catch (err) {
        console.log("Error in getCurrentCampaign: ", err);
      }
    },
    /**
     * Fetch all the campaigns
     */
    fetchAllCampaigns () {
      return this.$axios.get("/campaign")
        .then(response => { this.campaigns = response.data; })
        .catch(() => {
          this.showSnackbar({
            text: "Oups ! Une erreur s'est produite",
            type: "error",
            multiline: true
          });
        });
    },

    campaignLabel (campaign) {
      const opening = moment(campaign.datesCampaign.opening);
      return opening.format("MM/YYYY");
    },

    async fetchApplications () {
      try {
        this.loading = true;

        const response = await this.$axios.get("/applicationManagement", { params: { group: true, campaign: this.currentCampaign._id } });
        this.applications = response.data;

        // filter output if its not admin or the BRED
        if (!(this.isBRED || this.isAdmin)) {
          this.applications = response.data[this.user.institution.laboratory.acronym];
        }

        // get the application of a normal user
        if (!(this.isBRED || this.isAdmin || this.isViewer || this.isDirector) && this.applications.length > 0) {
          this.applicationId = this.applications[0].application._id;
        } else {
          this.applicationId = null;
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.showSnackbar({
          text: "Oups ! Une erreur s'est produite",
          type: "error",
          multiline: true
        });
      }
    },
    async submitForm (form) {
      try {
        const id = form._id;
        const bankInformation = form.bankInformation;
        form._id = undefined;
        form.bankInformation = undefined;

        await this.$axios.put("/applicationManagement/" + id, form);

        if (bankInformation instanceof File) {
          const formData = new FormData();
          formData.append("rib", bankInformation);
          await this.$axios.put(`/applicationManagement/upload/rib/${this.applicationId}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        }

        location.reload();
      } catch (error) {
        console.log("%c⧭", "color: #1d5673", error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.warning-message {
  text-align: center;
}
</style>

<template>
  <v-card>
    <v-card-text>
      <v-container class="pb-0">
        <!-- Application Type -->
        <v-layout class="mb-2">
          <v-flex>
            <span class="primary--text title mr-2">I. Type de dossier • Application type:</span>
            <span class="black--text title">{{ form.type }}</span>
          </v-flex>
        </v-layout>

        <v-divider class="my-3"></v-divider>

        <!-- Host information -->
        <v-layout wrap>
          <v-flex xs12 class="mb-1">
            <span class="primary--text title">II. Renseignement sur l'invitant</span>
          </v-flex>
          <v-flex xs4>
            <p class="grey--text">
              Nom:
              <span class="black--text">{{ form.host.lastName }}</span>
            </p>
          </v-flex>
          <v-flex xs4>
            <p class="grey--text">
              Prénom:
              <span class="black--text">{{ form.host.firstName }}</span>
            </p>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              E-mail:
              <span class="black--text">{{ form.host.email }}</span>
            </p>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              Laboratoire d'accueil:
              <span
                class="black--text"
              >{{ form.host.institution.laboratory.acronym }}</span>
            </p>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              Équipe de recherche:
              <span class="black--text">{{ form.host.institution.team }}</span>
            </p>
          </v-flex>
        </v-layout>

        <v-divider class="mb-3"></v-divider>

        <!-- Research Information -->
        <v-layout row wrap>
          <v-flex xs12 class="mb-1">
            <span class="primary--text title">III. Renseignements sur le projet de recherche</span>
          </v-flex>

          <v-flex xs12>
            <p class="grey--text">
              Titre du projet:
              <span class="black--text">{{ form.project.title }}</span>
            </p>
          </v-flex>

          <v-flex xs12>
            <p class="grey--text">
              Résumé du projet de recherche:
              <span class="black--text">{{ form.project.summary }}</span>
            </p>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              Date de début de séjour:
              <span
                class="black--text"
              >{{ form.stayDate.start | getDate }}</span>
            </p>
          </v-flex>
          <v-flex xs4>
            <p class="grey--text">
              Date de fin de séjour:
              <span class="black--text">{{ form.stayDate.end | getDate }}</span>
            </p>
          </v-flex>
          <v-flex xs4>
            <p class="grey--text">
              Estimation des frais de transports:
              <span
                class="black--text"
              >{{form.transportationCost}} €</span>
            </p>
          </v-flex>
          <v-flex xs6 v-if="form.project.document">
            <p class="grey--text">
              Document:
              <span class="black--text">
                <v-btn color="primary" dark small @click="downloadFile('project', id)">Télécharger</v-btn>
              </span>
            </p>
          </v-flex>
        </v-layout>

        <v-divider class="mb-3"></v-divider>

        <!-- Guest Information -->
        <v-layout wrap>
          <v-flex xs12 class="mb-1">
            <span class="primary--text title">IV. Renseignements sur le candidat:</span>
          </v-flex>

          <v-flex xs12 class="mb-1">
            <h3>1) Identité de l'invité</h3>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Sexe:
              <span class="black--text">{{ form.guest.identity.gender }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Nom:
              <span class="black--text">{{ form.guest.identity.lastName }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Prénom:
              <span class="black--text">{{ form.guest.identity.firstName }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              E-mail:
              <span class="black--text">{{ form.guest.identity.email }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Date de naissance:
              <span
                class="black--text"
              >{{ form.guest.identity.birthDate | getDate }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Nationalité:
              <span class="black--text">{{ form.guest.identity.nationality }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Pays de naissance:
              <span class="black--text">{{ form.guest.identity.nativeCountry }}</span>
            </p>
          </v-flex>

          <v-flex xs3>
            <p class="grey--text">
              Poste actuelle:
              <span class="black--text">{{ form.guest.identity.position }}</span>
            </p>
          </v-flex>

          <v-flex xs3 v-if="form.guest.alreadyInvited !== null">
            <p class="grey--text">
              Dernière date de visite:
              <span
                class="black--text"
              >{{ form.guest.alreadyInvited }}</span>
            </p>
          </v-flex>

          <v-flex xs12 class="mb-1">
            <h3>2) Adresse professionnelle complète</h3>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              Pays:
              <span class="black--text">{{ form.guest.professionalAddress.country }}</span>
            </p>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              Ville:
              <span class="black--text">{{ form.guest.professionalAddress.city }}</span>
            </p>
          </v-flex>

          <v-flex xs4>
            <p class="grey--text">
              Établissement d'origine:
              <span
                class="black--text"
              >{{ form.guest.professionalAddress.institution }}</span>
            </p>
          </v-flex>
        </v-layout>

        <v-divider class="mb-3"></v-divider>

        <!-- Extra Information -->
        <v-layout row wrap>
          <v-flex xs12 class="mb-1">
            <span class="primary--text title">V. Données complémentaires:</span>
          </v-flex>

          <v-flex xs12>
            <p class="grey--text">
              Activité de recherche:
              <span class="black--text">{{form.guest.researchActivities}}</span>
            </p>
          </v-flex>

          <v-flex xs12>
            <p class="grey--text">
              Keywords:
              <v-chip
                disabled
                class="keyword"
                v-for="item in form.keywords"
                v-bind:key="item.name"
              >{{ item.name }}</v-chip>
            </p>
          </v-flex>

          <v-flex xs6>
            <p class="grey--text">
              Photo ou logo de l'institution:
              <span class="black--text" v-if="form.photoVisible">
                <v-btn color="primary" dark small @click="downloadFile('photo', id)">Télécharger</v-btn>
              </span>
              <span class="black--text" v-else>
                <v-btn color="primary" dark small>Photo non visible</v-btn>
              </span>
            </p>
          </v-flex>

          <v-flex xs6>
            <p class="grey--text">
              CV:
              <span class="black--text">
                <v-btn color="primary" dark small @click="downloadFile('cv', id)">Télécharger</v-btn>
              </span>
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-divider class="mt-1"></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn color="secondary" :id="'closeBtn'" @click="$emit('close')">Fermer</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

function getNewForm (date) {
  return {
    guest: {
      identity: {
        gender: "",
        firstName: "",
        lastName: "",
        birthDate: date,
        email: "",
        nationality: "",
        nativeCountry: "",
        position: "",
        institution: ""
      },
      professionalAddress: {
        country: "",
        city: ""
      },
      alreadyInvited: null
    },
    host: {
      institution: {
        laboratory: "",
        team: ""
      },
      firstName: "",
      lastName: "",
      email: ""
    },
    stayDate: {
      start: date,
      end: date
    },
    project: {
      title: "",
      summary: ""
    },
    transportationCost: "",
    keywords: [],
    photoVisible: false,
    researchActivities: "",
    photo: "",
    cv: "",
    type: ""
  };
}

export default {
  name: "ApplicationInfo",
  props: {
    id: {
      type: String
    }
  },
  data () {
    return {
      form: getNewForm(this.today())
    };
  },
  computed: {
    ...mapGetters(["snackbarVisible"])
  },
  watch: {
    id (newId) {
      if (!newId) { this.form = getNewForm(this.today()); return; }
      this.fetchApplication();
    }
  },
  mounted () {
    if (!this.id) return;
    this.fetchApplication();
  },
  methods: {
    ...mapActions(["showSnackbar"]),

    /**
     * Fetch selected application
     */
    async fetchApplication () {
      await this.$axios.get("/application/" + this.id)
        .then((response) => {
          this.form = response.data;

          const dateInvitedBefore = this.form.guest.alreadyInvited;
          const parsedYear = dateInvitedBefore && (new Date(dateInvitedBefore)).getFullYear();

          // backward compatibility with full date
          if (parsedYear && (dateInvitedBefore.toString().length !== 4)) {
            this.form.guest.alreadyInvited = (parsedYear > 1) ? parsedYear : null;
          }
        })
        .catch((err) => console.log(err));
    },
    /**
     * Download application cv or photo
     * @param {string} type - type name (cv or photo)
     * @param {string} id - application's id
     */
    downloadFile (type, id) {
      return this.$axios.get(`/application/download/${type}/${id}`, { responseType: "blob" })
        .then((file) => {
          const url = window.URL.createObjectURL(new Blob([file.data]));
          const link = document.createElement("a");
          link.href = url;
          let fullname;

          if (type === "photo") fullname = this.form.photo;
          else if (type === "project") fullname = this.form.project.document;
          else fullname = this.form.cv;

          const name = fullname.split(/.*[/|\\]/)[1];
          link.setAttribute("download", name);

          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.showSnackbar({
            text: "Oups !\nErreur",
            type: "error",
            multiline: true
          });
        });
    }
  }
};

</script>

<template>
  <v-dialog v-model="value" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{ message }}
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Loading",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>
